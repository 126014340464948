:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}
*,
::after,
::before {
  box-sizing: border-box;
}

@-ms-viewport {
  width: device-width;
}
#header23 article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
#header23 {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}
[tabindex="-1"]:focus {
  outline: 0 !important;
}
#header23 hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
#header23 h1,
#header23 h2,
#header23 h3,
#header23 h4,
#header23 h5,
#header23 h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
#header23 p {
  margin-top: 0;
  margin-bottom: 1rem;
}
#header23 abbr[data-original-title],
#header23 abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}
#header23 address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
#header23 dl,
#header23 ol,
#header23 ul {
  margin-top: 0;
  margin-bottom: 1rem;
}
#header23 ol ol,
#header23 ol ul,
#header23 ul ol,
#header23 ul ul {
  margin-bottom: 0;
}
#header23 dt {
  font-weight: 700;
}
#header23 dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
#header23 blockquote {
  margin: 0 0 1rem;
}
#header23 dfn {
  font-style: italic;
}
#header23 b,
#header23 strong {
  font-weight: bolder;
}
#header23 small {
  font-size: 80%;
}
#header23 sub,
#header23 sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
#header23 sub {
  bottom: -0.25em;
}
#header23 sup {
  top: -0.5em;
}
#header23 a {
  color: #555;
  text-decoration: none;
  background-color: transparent;
  font-family: Inter, Sans-serif !important
  /* -webkit-text-decoration-skip: objects; */
}
#header23 a:hover {
  color: #555;
  text-decoration: none;
}
#header23 a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
#header23 a:not([href]):not([tabindex]):focus,
#header23 a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}
#header23 a:not([href]):not([tabindex]):focus {
  outline: 0;
}
#header23 code,
#header23 kbd,
#header23 pre,
#header23 samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
#header23 pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}
#header23 figure {
  margin: 0 0 1rem;
}
#header23 img {
  vertical-align: middle;
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
#header23 caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}
#header23 th {
  text-align: inherit;
}
#header23 label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
#header23 button {
  border-radius: 0;
}
#header23 button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
#header23 button,
#header23 input,
#header23 optgroup,
#header23 select,
#header23 textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
#header23 button,
#header23 input {
  overflow: visible;
}
#header23 button,
#header23 select {
  text-transform: none;
}
[type="reset"],
[type="submit"],
#header23 button,
#header23 html [type="button"] {
  -webkit-appearance: button;
}
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
#header23 button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
#header23 input[type="checkbox"],
#header23 input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
#header23 input[type="date"],
#header23 input[type="datetime-local"],
#header23 input[type="month"],
#header23 input[type="time"] {
  -webkit-appearance: listbox;
}
#header23 textarea {
  overflow: auto;
  resize: vertical;
}
#header23 fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
#header23 legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
#header23 progress {
  vertical-align: baseline;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
#header23 output {
  display: inline-block;
}
#header23 summary {
  display: list-item;
  cursor: pointer;
}
#header23 template {
  display: none;
}
[hidden] {
  display: none !important;
}
#header23 .h1,
#header23 .h2,
#header23 .h3,
#header23 .h4,
#header23 .h5,
#header23 .h6,
#header23 h1,
#header23 h2,
#header23 h3,
#header23 h4,
#header23 h5,
#header23 h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}
#header23 .h1,
#header23 h1 {
  font-size: 2.5rem;
}
#header23 .h2,
#header23 h2 {
  font-size: 2rem;
}
#header23 .h3,
#header23 h3 {
  font-size: 1.75rem;
}
#header23 .h4,
#header23 h4 {
  font-size: 1.5rem;
}
#header23 .h5,
#header23 h5 {
  font-size: 1.25rem;
}
#header23 .h6,
#header23 h6 {
  font-size: 1rem;
}
#header23 .lead {
  font-size: 1.25rem;
  font-weight: 300;
}
#header23 .display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}
#header23 .display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}
#header23 .display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}
#header23 .display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}
#header23 hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
#header23 .small,
#header23 small {
  font-size: 80%;
  font-weight: 400;
}
#header23 .mark,
#header23 mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}
#header23 .list-unstyled {
  padding-left: 0;
  list-style: none;
}
#header23 .list-inline {
  padding-left: 0;
  list-style: none;
}
#header23 .list-inline-item {
  display: inline-block;
}
#header23 .list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
#header23 .initialism {
  font-size: 90%;
  text-transform: uppercase;
}
#header23 .blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
#header23 .blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
#header23 .blockquote-footer::before {
  content: "\2014 \00A0";
}
#header23 .img-fluid {
  max-width: 100%;
  height: auto;
}
#header23 .img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}
#header23 .figure {
  display: inline-block;
}
#header23 .figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}
#header23 .figure-caption {
  font-size: 90%;
  color: #6c757d;
}
#header23 code,
#header23 kbd,
#header23 pre,
#header23 samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
}
#header23 code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}
#header23 a > code {
  color: inherit;
}
#header23 kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
#header23 kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}
#header23 pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
#header23 pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
#header23 .pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}
/* #header23 .container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
} */
/* @media (min-width: 576px) {
  #header23 .container {
    max-width: 540px;
  }
} */
/* @media (min-width: 768px) {
  #header23 .container {
    max-width: 720px;
  }
} */
/* @media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
} */
/* @media (min-width: 1200px) {
 
  .container {
    max-width: 1140px;
  }
} */
#header23 .container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
#header23 .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}
#header23 .col,
#header23 .col-1,
#header23 .col-10,
#header23 .col-11,
#header23 .col-2,
#header23 .col-3,
#header23 .col-4,
#header23 .col-5,
#header23 .col-6,
#header23 .col-7,
#header23 .col-8,
#header23 .col-9,
#header23 .col-auto,
#header23 .col-lg,
#header23 .col-lg-1,
#header23 .col-lg-10,
#header23 .col-lg-11,
#header23 .col-lg-12,
#header23 .col-lg-2,
#header23 .col-lg-3,
#header23 .col-lg-4,
#header23 .col-lg-5,
#header23 .col-lg-6,
#header23 .col-lg-7,
#header23 .col-lg-8,
#header23 .col-lg-9,
#header23 .col-lg-auto,
#header23 .col-md,
#header23 .col-md-1,
#header23 .col-md-10,
#header23 .col-md-11,
#header23 .col-md-12,
#header23 .col-md-2,
#header23 .col-md-3,
#header23 .col-md-4,
#header23 .col-md-5,
#header23 .col-md-6,
#header23 .col-md-7,
#header23 .col-md-8,
#header23 .col-md-9,
#header23 .col-md-auto,
#header23 .col-sm,
#header23 .col-sm-1,
#header23 .col-sm-10,
#header23 .col-sm-11,
#header23 .col-sm-12,
#header23 .col-sm-2,
#header23 .col-sm-3,
#header23 .col-sm-4,
#header23 .col-sm-5,
#header23 .col-sm-6,
#header23 .col-sm-7,
#header23 .col-sm-8,
#header23 .col-sm-9,
#header23 .col-sm-auto,
#header23 .col-xl,
#header23 .col-xl-1,
#header23 .col-xl-10,
#header23 .col-xl-11,
#header23 .col-xl-12,
#header23 .col-xl-2,
#header23 .col-xl-3,
#header23 .col-xl-4,
#header23 .col-xl-5,
#header23 .col-xl-6,
#header23 .col-xl-7,
#header23 .col-xl-8,
#header23 .col-xl-9,
#header23 .col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
#header23 .col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
#header23 .col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}
#header23 .col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}
#header23 .col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}
#header23 .col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}
#header23 .col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
#header23 .col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}
#header23 .col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
#header23 .col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}
#header23 .col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}
#header23 .col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}
#header23 .col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}
#header23 .col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}
#header23 .col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
.order-first {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
}
.order-last {
  -webkit-box-ordinal-group: 14;
  -ms-flex-order: 13;
  order: 13;
}
.order-0 {
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
}
.order-1 {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}
.order-2 {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}
.order-3 {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
}
.order-4 {
  -webkit-box-ordinal-group: 5;
  -ms-flex-order: 4;
  order: 4;
}
.order-5 {
  -webkit-box-ordinal-group: 6;
  -ms-flex-order: 5;
  order: 5;
}
.order-6 {
  -webkit-box-ordinal-group: 7;
  -ms-flex-order: 6;
  order: 6;
}
.order-7 {
  -webkit-box-ordinal-group: 8;
  -ms-flex-order: 7;
  order: 7;
}
.order-8 {
  -webkit-box-ordinal-group: 9;
  -ms-flex-order: 8;
  order: 8;
}
.order-9 {
  -webkit-box-ordinal-group: 10;
  -ms-flex-order: 9;
  order: 9;
}
.order-10 {
  -webkit-box-ordinal-group: 11;
  -ms-flex-order: 10;
  order: 10;
}
.order-11 {
  -webkit-box-ordinal-group: 12;
  -ms-flex-order: 11;
  order: 11;
}
.order-12 {
  -webkit-box-ordinal-group: 13;
  -ms-flex-order: 12;
  order: 12;
}
.offset-1 {
  margin-left: 8.333333%;
}
.offset-2 {
  margin-left: 16.666667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.333333%;
}
.offset-5 {
  margin-left: 41.666667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.333333%;
}
.offset-8 {
  margin-left: 66.666667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.333333%;
}
.offset-11 {
  margin-left: 91.666667%;
}
@media (min-width: 576px) {
  #header23 .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  #header23 .col-sm-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  #header23 .col-sm-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  #header23 .col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  #header23 .col-sm-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  #header23 .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  #header23 .col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  #header23 .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  #header23 .col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  #header23 .col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  #header23 .col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  #header23 .col-sm-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  #header23 .col-sm-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  #header23 .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-sm-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 768px) {
  #header23 .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  #header23 .col-md-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  #header23 .col-md-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  #header23 .col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  #header23 .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  #header23 .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  #header23 .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  #header23 .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  #header23 .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  #header23 .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  #header23 .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  #header23 .col-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  #header23 .col-md-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  #header23 .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-md-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-md-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-md-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-md-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-md-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-md-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-md-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-md-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-md-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-md-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-md-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-md-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-md-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-md-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 992px) {
  #header23 .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  #header23 .col-lg-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  #header23 .col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  #header23 .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  #header23 .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  #header23 .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  #header23 .col-lg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  #header23 .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  #header23 .col-lg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  #header23 .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  #header23 .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  #header23 .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  #header23 .col-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  #header23 .col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-lg-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 1200px) {
  #header23 .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  #header23 .col-xl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  #header23 .col-xl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  #header23 .col-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  #header23 .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  #header23 .col-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  #header23 .col-xl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  #header23 .col-xl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  #header23 .col-xl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  #header23 .col-xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  #header23 .col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  #header23 .col-xl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  #header23 .col-xl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  #header23 .col-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xl-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}
#header23 .form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
#header23 .form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
#header23 .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
#header23 .form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
#header23 .form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}
#header23 .form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
#header23 .form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
#header23 .form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
#header23 .form-control:disabled,
#header23 .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem+2px);
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}
.col-form-label {
  padding-top: calc(0.375rem+1px);
  padding-bottom: calc(0.375rem+1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}
.col-form-label-lg {
  padding-top: calc(0.5rem+1px);
  padding-bottom: calc(0.5rem+1px);
  font-size: 1.25rem;
  line-height: 1.5;
}
.col-form-label-sm {
  padding-top: calc(0.25rem+1px);
  padding-bottom: calc(0.25rem+1px);
  font-size: 0.875rem;
  line-height: 1.5;
}
.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm,
.input-group-lg > .form-control-plaintext.form-control,
.input-group-lg > .input-group-append > .form-control-plaintext.btn-header,
.input-group-lg
  > .input-group-append
  > .form-control-plaintext.input-group-text,
.input-group-lg > .input-group-prepend > .form-control-plaintext.btn-header,
.input-group-lg
  > .input-group-prepend
  > .form-control-plaintext.input-group-text,
.input-group-sm > .form-control-plaintext.form-control,
.input-group-sm > .input-group-append > .form-control-plaintext.btn-header,
.input-group-sm
  > .input-group-append
  > .form-control-plaintext.input-group-text,
.input-group-sm > .input-group-prepend > .form-control-plaintext.btn-header,
.input-group-sm
  > .input-group-prepend
  > .form-control-plaintext.input-group-text {
  padding-right: 0;
  padding-left: 0;
}
.form-control-sm,
.input-group-sm > .form-control,
.input-group-sm > .input-group-append > .btn-header,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn-header,
.input-group-sm > .input-group-prepend > .input-group-text {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.input-group-sm
  > .input-group-append
  > select.btn-header:not([size]):not([multiple]),
.input-group-sm
  > .input-group-append
  > select.input-group-text:not([size]):not([multiple]),
.input-group-sm
  > .input-group-prepend
  > select.btn-header:not([size]):not([multiple]),
.input-group-sm
  > .input-group-prepend
  > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > select.form-control:not([size]):not([multiple]),
select.form-control-sm:not([size]):not([multiple]) {
  height: calc(1.8125rem+2px);
}
.form-control-lg,
.input-group-lg > .form-control,
.input-group-lg > .input-group-append > .btn-header,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn-header,
.input-group-lg > .input-group-prepend > .input-group-text {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.input-group-lg
  > .input-group-append
  > select.btn-header:not([size]):not([multiple]),
.input-group-lg
  > .input-group-append
  > select.input-group-text:not([size]):not([multiple]),
.input-group-lg
  > .input-group-prepend
  > select.btn-header:not([size]):not([multiple]),
.input-group-lg
  > .input-group-prepend
  > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > select.form-control:not([size]):not([multiple]),
select.form-control-lg:not([size]):not([multiple]) {
  height: calc(2.875rem+2px);
}
#header23 .form-group {
  margin-bottom: 1rem;
}
#header23 .form-text {
  display: block;
  margin-top: 0.25rem;
}
#header23 .form-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
#header23 .form-row > .col,
#header23 .form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}
#header23 .form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}
.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}
.form-check-label {
  margin-bottom: 0;
}
.form-check-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}
.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}
.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: 0.2rem;
}
.custom-select.is-valid,
.form-control.is-valid,
.was-validated .custom-select:valid,
.was-validated .form-control:valid {
  border-color: #28a745;
}
.custom-select.is-valid:focus,
.form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip,
.form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip,
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip,
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip {
  display: block;
}
.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
  color: #28a745;
}
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip,
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip {
  display: block;
}
.custom-control-input.is-valid ~ .custom-control-label,
.was-validated .custom-control-input:valid ~ .custom-control-label {
  color: #28a745;
}
.custom-control-input.is-valid ~ .custom-control-label::before,
.was-validated .custom-control-input:valid ~ .custom-control-label::before {
  background-color: #71dd8a;
}
.custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip,
.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip {
  display: block;
}
.custom-control-input.is-valid:checked ~ .custom-control-label::before,
.was-validated
  .custom-control-input:valid:checked
  ~ .custom-control-label::before {
  background-color: #34ce57;
}
.custom-control-input.is-valid:focus ~ .custom-control-label::before,
.was-validated
  .custom-control-input:valid:focus
  ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.custom-file-input.is-valid ~ .custom-file-label,
.was-validated .custom-file-input:valid ~ .custom-file-label {
  border-color: #28a745;
}
.custom-file-input.is-valid ~ .custom-file-label::before,
.was-validated .custom-file-input:valid ~ .custom-file-label::before {
  border-color: inherit;
}
.custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip,
.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip {
  display: block;
}
.custom-file-input.is-valid:focus ~ .custom-file-label,
.was-validated .custom-file-input:valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: 0.2rem;
}
.custom-select.is-invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545;
}
.custom-select.is-invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip,
.form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip,
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip,
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip {
  display: block;
}
.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label {
  color: #dc3545;
}
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip,
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip {
  display: block;
}
.custom-control-input.is-invalid ~ .custom-control-label,
.was-validated .custom-control-input:invalid ~ .custom-control-label {
  color: #dc3545;
}
.custom-control-input.is-invalid ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid ~ .custom-control-label::before {
  background-color: #efa2a9;
}
.custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip,
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip {
  display: block;
}
.custom-control-input.is-invalid:checked ~ .custom-control-label::before,
.was-validated
  .custom-control-input:invalid:checked
  ~ .custom-control-label::before {
  background-color: #e4606d;
}
.custom-control-input.is-invalid:focus ~ .custom-control-label::before,
.was-validated
  .custom-control-input:invalid:focus
  ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.custom-file-input.is-invalid ~ .custom-file-label,
.was-validated .custom-file-input:invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.custom-file-input.is-invalid ~ .custom-file-label::before,
.was-validated .custom-file-input:invalid ~ .custom-file-label::before {
  border-color: inherit;
}
.custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip,
.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip {
  display: block;
}
.custom-file-input.is-invalid:focus ~ .custom-file-label,
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.form-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  àheader23 .form-inline .input-group {
    width: auto;
  }
  .form-inline .form-check {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}
#header23 .btn-header {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-header:focus,
.btn-header:hover {
  text-decoration: none;
}
.btn-header.focus,
.btn-header:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn-header.disabled,
.btn-header:disabled {
  opacity: 0.65;
}
.btn-header:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.btn-header:not(:disabled):not(.disabled).active,
.btn-header:not(:disabled):not(.disabled):active {
  background-image: none;
}
a.btn-header.disabled,
fieldset:disabled a.btn-header {
  pointer-events: none;
}
.btn-header-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-header-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn-header-primary.focus,
.btn-header-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-header-primary.disabled,
.btn-header-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-header-primary:not(:disabled):not(.disabled).active,
.btn-header-primary:not(:disabled):not(.disabled):active,
.show > .btn-header-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
.btn-header-primary:not(:disabled):not(.disabled).active:focus,
.btn-header-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-header-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-header-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-header-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-header-secondary.focus,
.btn-header-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-header-secondary.disabled,
.btn-header-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-header-secondary:not(:disabled):not(.disabled).active,
.btn-header-secondary:not(:disabled):not(.disabled):active,
.show > .btn-header-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-header-secondary:not(:disabled):not(.disabled).active:focus,
.btn-header-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-header-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-header-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-header-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-header-success.focus,
.btn-header-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-header-success.disabled,
.btn-header-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-header-success:not(:disabled):not(.disabled).active,
.btn-header-success:not(:disabled):not(.disabled):active,
.show > .btn-header-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-header-success:not(:disabled):not(.disabled).active:focus,
.btn-header-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-header-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-header-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-header-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-header-info.focus,
.btn-header-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-header-info.disabled,
.btn-header-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-header-info:not(:disabled):not(.disabled).active,
.btn-header-info:not(:disabled):not(.disabled):active,
.show > .btn-header-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-header-info:not(:disabled):not(.disabled).active:focus,
.btn-header-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-header-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-header-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-header-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-header-warning.focus,
.btn-header-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-header-warning.disabled,
.btn-header-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-header-warning:not(:disabled):not(.disabled).active,
.btn-header-warning:not(:disabled):not(.disabled):active,
.show > .btn-header-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-header-warning:not(:disabled):not(.disabled).active:focus,
.btn-header-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-header-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-header-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-header-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-header-danger.focus,
.btn-header-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-header-danger.disabled,
.btn-header-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-header-danger:not(:disabled):not(.disabled).active,
.btn-header-danger:not(:disabled):not(.disabled):active,
.show > .btn-header-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-header-danger:not(:disabled):not(.disabled).active:focus,
.btn-header-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-header-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-header-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-header-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-header-light.focus,
.btn-header-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-header-light.disabled,
.btn-header-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-header-light:not(:disabled):not(.disabled).active,
.btn-header-light:not(:disabled):not(.disabled):active,
.show > .btn-header-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-header-light:not(:disabled):not(.disabled).active:focus,
.btn-header-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-header-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-header-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-header-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-header-dark.focus,
.btn-header-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-header-dark.disabled,
.btn-header-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-header-dark:not(:disabled):not(.disabled).active,
.btn-header-dark:not(:disabled):not(.disabled):active,
.show > .btn-header-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-header-dark:not(:disabled):not(.disabled).active:focus,
.btn-header-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-header-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-header-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff;
}
.btn-header-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-header-outline-primary.focus,
.btn-header-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-header-outline-primary.disabled,
.btn-header-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}
.btn-header-outline-primary:not(:disabled):not(.disabled).active,
.btn-header-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-header-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-header-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-header-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-header-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-header-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d;
}
.btn-header-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-header-outline-secondary.focus,
.btn-header-outline-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-header-outline-secondary.disabled,
.btn-header-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-header-outline-secondary:not(:disabled):not(.disabled).active,
.btn-header-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-header-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-header-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-header-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-header-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-header-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745;
}
.btn-header-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-header-outline-success.focus,
.btn-header-outline-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-header-outline-success.disabled,
.btn-header-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-header-outline-success:not(:disabled):not(.disabled).active,
.btn-header-outline-success:not(:disabled):not(.disabled):active,
.show > .btn-header-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-header-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-header-outline-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-header-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-header-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8;
}
.btn-header-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-header-outline-info.focus,
.btn-header-outline-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-header-outline-info.disabled,
.btn-header-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-header-outline-info:not(:disabled):not(.disabled).active,
.btn-header-outline-info:not(:disabled):not(.disabled):active,
.show > .btn-header-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-header-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-header-outline-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-header-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-header-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107;
}
.btn-header-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-header-outline-warning.focus,
.btn-header-outline-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-header-outline-warning.disabled,
.btn-header-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-header-outline-warning:not(:disabled):not(.disabled).active,
.btn-header-outline-warning:not(:disabled):not(.disabled):active,
.show > .btn-header-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-header-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-header-outline-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-header-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-header-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545;
}
.btn-header-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-header-outline-danger.focus,
.btn-header-outline-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-header-outline-danger.disabled,
.btn-header-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-header-outline-danger:not(:disabled):not(.disabled).active,
.btn-header-outline-danger:not(:disabled):not(.disabled):active,
.show > .btn-header-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-header-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-header-outline-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-header-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-header-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
}
.btn-header-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-header-outline-light.focus,
.btn-header-outline-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-header-outline-light.disabled,
.btn-header-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-header-outline-light:not(:disabled):not(.disabled).active,
.btn-header-outline-light:not(:disabled):not(.disabled):active,
.show > .btn-header-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-header-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-header-outline-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-header-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-header-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
}
.btn-header-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-header-outline-dark.focus,
.btn-header-outline-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-header-outline-dark.disabled,
.btn-header-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-header-outline-dark:not(:disabled):not(.disabled).active,
.btn-header-outline-dark:not(:disabled):not(.disabled):active,
.show > .btn-header-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-header-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-header-outline-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-header-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-header-link {
  font-weight: 400;
  color: #007bff;
  background-color: transparent;
}
.btn-header-link:hover {
  color: #0056b3;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}
.btn-header-link.focus,
.btn-header-link:focus {
  text-decoration: underline;
  border-color: transparent;
  box-shadow: none;
}
.btn-header-link.disabled,
.btn-header-link:disabled {
  color: #6c757d;
}
.btn-header-group-lg > .btn-header,
.btn-header-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.btn-header-group-sm > .btn-header,
.btn-header-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.btn-header-block {
  display: block;
  width: 100%;
}
.btn-header-block + .btn-header-block {
  margin-top: 0.5rem;
}
input[type="button"].btn-header-block,
input[type="reset"].btn-header-block,
input[type="submit"].btn-header-block {
  width: 100%;
}
.fade {
  opacity: 0;
  transition: opacity 0.15s linear;
}
.fade.show {
  opacity: 1;
}
.collapse {
  display: none;
}
.collapse.show {
  display: block;
}
tr.collapse.show {
  display: table-row;
}
tbody.collapse.show {
  display: table-row-group;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
.dropdown,
.dropup {
  position: relative;
}
.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-menu {
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}
.dropleft .dropdown-menu {
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}
.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #6c757d;
  background-color: transparent;
}
.dropdown-menu.show {
  display: block;
}
.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}
.btn-header-group,
.btn-header-group-vertical {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}
.btn-header-group-vertical > .btn-header,
.btn-header-group > .btn-header {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
}
.btn-header-group-vertical > .btn-header:hover,
.btn-header-group > .btn-header:hover {
  z-index: 1;
}
.btn-header-group-vertical > .btn-header.active,
.btn-header-group-vertical > .btn-header:active,
.btn-header-group-vertical > .btn-header:focus,
.btn-header-group > .btn-header.active,
.btn-header-group > .btn-header:active,
.btn-header-group > .btn-header:focus {
  z-index: 1;
}
.btn-header-group .btn-header + .btn-header,
.btn-header-group .btn-header + .btn-header-group,
.btn-header-group .btn-header-group + .btn-header,
.btn-header-group .btn-header-group + .btn-header-group,
.btn-header-group-vertical .btn-header + .btn-header,
.btn-header-group-vertical .btn-header + .btn-header-group,
.btn-header-group-vertical .btn-header-group + .btn-header,
.btn-header-group-vertical .btn-header-group + .btn-header-group {
  margin-left: -1px;
}
.btn-header-toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.btn-header-toolbar .input-group {
  width: auto;
}
.btn-header-group > .btn-header:first-child {
  margin-left: 0;
}
.btn-header-group > .btn-header-group:not(:last-child) > .btn-header,
.btn-header-group > .btn-header:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-header-group > .btn-header-group:not(:first-child) > .btn-header,
.btn-header-group > .btn-header:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after {
  margin-left: 0;
}
.btn-header-group-sm > .btn-header + .dropdown-toggle-split,
.btn-header-sm + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}
.btn-header-group-lg > .btn-header + .dropdown-toggle-split,
.btn-header-lg + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.btn-header-group-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.btn-header-group-vertical .btn-header,
.btn-header-group-vertical .btn-header-group {
  width: 100%;
}
.btn-header-group-vertical > .btn-header + .btn-header,
.btn-header-group-vertical > .btn-header + .btn-header-group,
.btn-header-group-vertical > .btn-header-group + .btn-header,
.btn-header-group-vertical > .btn-header-group + .btn-header-group {
  margin-top: -1px;
  margin-left: 0;
}
.btn-header-group-vertical > .btn-header-group:not(:last-child) > .btn-header,
.btn-header-group-vertical
  > .btn-header:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-header-group-vertical > .btn-header-group:not(:first-child) > .btn-header,
.btn-header-group-vertical > .btn-header:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.btn-header-group-toggle > .btn-header,
.btn-header-group-toggle > .btn-header-group > .btn-header {
  margin-bottom: 0;
}
.btn-header-group-toggle > .btn-header input[type="checkbox"],
.btn-header-group-toggle > .btn-header input[type="radio"],
.btn-header-group-toggle
  > .btn-header-group
  > .btn-header
  input[type="checkbox"],
.btn-header-group-toggle > .btn-header-group > .btn-header input[type="radio"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
#header23 .input-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}
#header23 .input-group > .custom-file,
#header23 .input-group > .custom-select,
#header23 .input-group > .form-control {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.input-group > .custom-file:focus,
.input-group > .custom-select:focus,
.input-group > .form-control:focus {
  z-index: 3;
}
.input-group > .custom-file + .custom-file,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .form-control,
.input-group > .custom-select + .custom-file,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .form-control,
.input-group > .form-control + .custom-file,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .form-control {
  margin-left: -1px;
}
.input-group > .custom-select:not(:last-child),
.input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-select:not(:first-child),
.input-group > .form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::before {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label,
.input-group > .custom-file:not(:first-child) .custom-file-label::before {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group-append,
.input-group-prepend {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.input-group-append .btn-header,
.input-group-prepend .btn-header {
  position: relative;
  z-index: 2;
}
.input-group-append .btn-header + .btn-header,
.input-group-append .btn-header + .input-group-text,
.input-group-append .input-group-text + .btn-header,
.input-group-append .input-group-text + .input-group-text,
.input-group-prepend .btn-header + .btn-header,
.input-group-prepend .btn-header + .input-group-text,
.input-group-prepend .input-group-text + .btn-header,
.input-group-prepend .input-group-text + .input-group-text {
  margin-left: -1px;
}
.input-group-prepend {
  margin-right: -1px;
}
.input-group-append {
  margin-left: -1px;
}
.input-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type="checkbox"],
.input-group-text input[type="radio"] {
  margin-top: 0;
}
.input-group
  > .input-group-append:last-child
  > .btn-header:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child),
.input-group > .input-group-append:not(:last-child) > .btn-header,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-prepend > .btn-header,
.input-group > .input-group-prepend > .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .input-group-append > .btn-header,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn-header:not(:first-child),
.input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child),
.input-group > .input-group-prepend:not(:first-child) > .btn-header,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}
.custom-control-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem;
}
.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #007bff;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
}
.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}
.custom-control-label {
  margin-bottom: 0;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #dee2e6;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}
.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}
.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  background-color: #007bff;
}
.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}
.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-checkbox
  .custom-control-input:disabled:indeterminate
  ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}
.custom-radio
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem+2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff
    url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
    no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075),
    0 0 5px rgba(128, 189, 255, 0.5);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  opacity: 0;
}
.custom-select-sm {
  height: calc(1.8125rem+2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%;
}
.custom-select-lg {
  height: calc(2.875rem+2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%;
}
.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem+2px);
  margin-bottom: 0;
}
.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem+2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-control {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-file-input:focus ~ .custom-file-control::before {
  border-color: #80bdff;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem+2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(calc(2.25rem+2px) - 1px * 2);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: 1px solid #ced4da;
  border-radius: 0 0.25rem 0.25rem 0;
}
.nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:focus,
.nav-link:hover {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
}
.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}
.nav-fill .nav-item {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
}
.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
.navbar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar > .container,
.navbar > .container-fluid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:focus,
.navbar-brand:hover {
  text-decoration: none;
}
.navbar-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}
.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:focus,
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}
@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}
.navbar-expand {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .dropdown-menu-right {
  right: 0;
  left: auto;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
}
.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
  color: #fff;
}
#header23 .card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
}
.card-title {
  margin-bottom: 0.75rem;
}
.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}
.card-text:last-child {
  margin-bottom: 0;
}
.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}
.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}
.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}
.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}
.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}
.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}
.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card-deck {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0;
    flex: 1 0 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}
.card-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .card-group > .card {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0;
    flex: 1 0 0;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:first-child .card-header,
  .card-group > .card:first-child .card-img-top {
    border-top-right-radius: 0;
  }
  .card-group > .card:first-child .card-footer,
  .card-group > .card:first-child .card-img-bottom {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:last-child .card-header,
  .card-group > .card:last-child .card-img-top {
    border-top-left-radius: 0;
  }
  .card-group > .card:last-child .card-footer,
  .card-group > .card:last-child .card-img-bottom {
    border-bottom-left-radius: 0;
  }
  .card-group > .card:only-child {
    border-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-header,
  .card-group > .card:only-child .card-img-top {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-footer,
  .card-group > .card:only-child .card-img-bottom {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0;
  }
  .card-group
    > .card:not(:first-child):not(:last-child):not(:only-child)
    .card-footer,
  .card-group
    > .card:not(:first-child):not(:last-child):not(:only-child)
    .card-header,
  .card-group
    > .card:not(:first-child):not(:last-child):not(:only-child)
    .card-img-bottom,
  .card-group
    > .card:not(:first-child):not(:last-child):not(:only-child)
    .card-img-top {
    border-radius: 0;
  }
}
.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}
.breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}
.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}
.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.badge:empty {
  display: none;
}
.btn-header .badge {
  position: relative;
  top: -1px;
}
.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}
.badge-primary {
  color: #fff;
  background-color: #007bff;
}
.badge-primary[href]:focus,
.badge-primary[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #0062cc;
}
.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
.badge-secondary[href]:focus,
.badge-secondary[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #545b62;
}
.badge-success {
  color: #fff;
  background-color: #28a745;
}
.badge-success[href]:focus,
.badge-success[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #1e7e34;
}
.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
.badge-info[href]:focus,
.badge-info[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #117a8b;
}
.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
.badge-warning[href]:focus,
.badge-warning[href]:hover {
  color: #212529;
  text-decoration: none;
  background-color: #d39e00;
}
.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
.badge-danger[href]:focus,
.badge-danger[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #bd2130;
}
.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
.badge-light[href]:focus,
.badge-light[href]:hover {
  color: #212529;
  text-decoration: none;
  background-color: #dae0e5;
}
.badge-dark {
  color: #fff;
  background-color: #343a40;
}
.badge-dark[href]:focus,
.badge-dark[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #1d2124;
}
.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}
.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.alert-heading {
  color: inherit;
}
.alert-link {
  font-weight: 700;
}
.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}
.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.alert-primary hr {
  border-top-color: #9fcdff;
}
.alert-primary .alert-link {
  color: #002752;
}
.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}
.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}
.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}
.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}
.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}
.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}
@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  background-color: #007bff;
  transition: width 0.6s ease;
}
.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}
.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}
.media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.media-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.list-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}
.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:focus,
.list-group-item-action:hover {
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}
#header23 .list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
#header23 .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.list-group-item:focus,
.list-group-item:hover {
  z-index: 1;
  text-decoration: none;
}
.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}
.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}
.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}
.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
  color: #004085;
  background-color: #9fcdff;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}
.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}
.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}
.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}
.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}
.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}
.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}
.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:focus,
.close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}
#header23 button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}
#header23 .modal-header-open {
  overflow: hidden;
}
#header23 .modal-header {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}
#header23 .modal-header-open .modal-header {
  overflow-x: hidden;
  overflow-y: auto;
}
#header23 .modal-header-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
#header23 .modal-header.fade .modal-header-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -25%);
  transform: translate(0, -25%);
}
#header23 .modal-header.show .modal-header-dialog {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}
#header23 .modal-header-dialog-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}
#header23 .modal-header-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}
#header23 .modal-header-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}
#header23 .modal-header-backdrop.fade {
  opacity: 0;
}
#header23 .modal-header-backdrop.show {
  opacity: 0.5;
}
#header23 .modal-header-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
#header23 .modal-header-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}
#header23 .modal-header-title {
  margin-bottom: 0;
  line-height: 1.5;
}
#header23 .modal-header-body {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}
#header23 .modal-header-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
}
#header23 .modal-header-footer > :not(:first-child) {
  margin-left: 0.25rem;
}
#header23 .modal-header-footer > :not(:last-child) {
  margin-right: 0.25rem;
}
#header23 .modal-header-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  #header23 .modal-header-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  #header23 .modal-header-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  #header23 .modal-header-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  #header23 .modal-header-lg {
    max-width: 800px;
  }
}
#header23 .tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
#header23 .tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-tooltip-auto[x-placement^="top"],
.bs-tooltip-top {
  padding: 0.4rem 0;
}
.bs-tooltip-auto[x-placement^="top"] .arrow,
.bs-tooltip-top .arrow {
  bottom: 0;
}
.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}
.bs-tooltip-auto[x-placement^="right"],
.bs-tooltip-right {
  padding: 0 0.4rem;
}
.bs-tooltip-auto[x-placement^="right"] .arrow,
.bs-tooltip-right .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}
.bs-tooltip-auto[x-placement^="bottom"],
.bs-tooltip-bottom {
  padding: 0.4rem 0;
}
.bs-tooltip-auto[x-placement^="bottom"] .arrow,
.bs-tooltip-bottom .arrow {
  top: 0;
}
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}
.bs-tooltip-auto[x-placement^="left"],
.bs-tooltip-left {
  padding: 0 0.4rem;
}
.bs-tooltip-auto[x-placement^="left"] .arrow,
.bs-tooltip-left .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}
.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::after,
.popover .arrow::before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-popover-auto[x-placement^="top"],
.bs-popover-top {
  margin-bottom: 0.5rem;
}
.bs-popover-auto[x-placement^="top"] .arrow,
.bs-popover-top .arrow {
  bottom: calc((0.5rem+1px) * -1);
}
.bs-popover-auto[x-placement^="top"] .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-top .arrow::before {
  border-width: 0.5rem 0.5rem 0;
}
.bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::before {
  bottom: 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^="top"] .arrow::after,
.bs-popover-top .arrow::after {
  bottom: 1px;
  border-top-color: #fff;
}
.bs-popover-auto[x-placement^="right"],
.bs-popover-right {
  margin-left: 0.5rem;
}
.bs-popover-auto[x-placement^="right"] .arrow,
.bs-popover-right .arrow {
  left: calc((0.5rem+1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-auto[x-placement^="right"] .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-right .arrow::before {
  border-width: 0.5rem 0.5rem 0.5rem 0;
}
.bs-popover-auto[x-placement^="right"] .arrow::before,
.bs-popover-right .arrow::before {
  left: 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^="right"] .arrow::after,
.bs-popover-right .arrow::after {
  left: 1px;
  border-right-color: #fff;
}
.bs-popover-auto[x-placement^="bottom"],
.bs-popover-bottom {
  margin-top: 0.5rem;
}
.bs-popover-auto[x-placement^="bottom"] .arrow,
.bs-popover-bottom .arrow {
  top: calc((0.5rem+1px) * -1);
}
.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-bottom .arrow::before {
  border-width: 0 0.5rem 0.5rem 0.5rem;
}
.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.bs-popover-bottom .arrow::before {
  top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.bs-popover-bottom .arrow::after {
  top: 1px;
  border-bottom-color: #fff;
}
.bs-popover-auto[x-placement^="bottom"] .popover-header::before,
.bs-popover-bottom .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}
.bs-popover-auto[x-placement^="left"],
.bs-popover-left {
  margin-right: 0.5rem;
}
.bs-popover-auto[x-placement^="left"] .arrow,
.bs-popover-left .arrow {
  right: calc((0.5rem+1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-auto[x-placement^="left"] .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-left .arrow::before {
  border-width: 0.5rem 0 0.5rem 0.5rem;
}
.bs-popover-auto[x-placement^="left"] .arrow::before,
.bs-popover-left .arrow::before {
  right: 0;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^="left"] .arrow::after,
.bs-popover-left .arrow::after {
  right: 1px;
  border-left-color: #fff;
}
.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}
.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}
.carousel {
  position: relative;
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-item {
  position: relative;
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  transition: -webkit-transform 0.6s ease;
  transition: transform 0.6s ease;
  transition: transform 0.6s ease, -webkit-transform 0.6s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000px;
  perspective: 1000px;
}
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block;
}
.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0;
}
.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
@supports (
  (-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)
) {
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.active.carousel-item-right,
.carousel-item-next {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
@supports (
  (-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)
) {
  .active.carousel-item-right,
  .carousel-item-next {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
.active.carousel-item-left,
.carousel-item-prev {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
@supports (
  (-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)
) {
  .active.carousel-item-left,
  .carousel-item-prev {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
}
.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}
.carousel-control-prev {
  left: 0;
}
.carousel-control-next {
  right: 0;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}
.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  background-color: rgba(255, 255, 255, 0.5);
}
.carousel-indicators li::before {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}
.carousel-indicators li::after {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}
.carousel-indicators .active {
  background-color: #fff;
}
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}
.align-baseline {
  vertical-align: baseline !important;
}
.align-top {
  vertical-align: top !important;
}
.align-middle {
  vertical-align: middle !important;
}
.align-bottom {
  vertical-align: bottom !important;
}
.align-text-bottom {
  vertical-align: text-bottom !important;
}
.align-text-top {
  vertical-align: text-top !important;
}
.bg-primary {
  background-color: #007bff !important;
}
a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: #0062cc !important;
}
.bg-secondary {
  background-color: #6c757d !important;
}
a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
  background-color: #545b62 !important;
}
.bg-success {
  background-color: #28a745 !important;
}
a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
  background-color: #1e7e34 !important;
}
.bg-info {
  background-color: #17a2b8 !important;
}
a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
  background-color: #117a8b !important;
}
.bg-warning {
  background-color: #ffc107 !important;
}
a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
  background-color: #d39e00 !important;
}
.bg-danger {
  background-color: #dc3545 !important;
}
a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
  background-color: #bd2130 !important;
}
.bg-light {
  background-color: #f8f9fa !important;
}
a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
  background-color: #dae0e5 !important;
}
.bg-dark {
  background-color: #343a40 !important;
}
a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
  background-color: #1d2124 !important;
}
.bg-white {
  background-color: #fff !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.border {
  border: 1px solid #dee2e6 !important;
}
.border-top {
  border-top: 1px solid #dee2e6 !important;
}
.border-right {
  border-right: 1px solid #dee2e6 !important;
}
.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}
.border-left {
  border-left: 1px solid #dee2e6 !important;
}
.border-0 {
  border: 0 !important;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-right-0 {
  border-right: 0 !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-left-0 {
  border-left: 0 !important;
}
.border-primary {
  border-color: #007bff !important;
}
.border-secondary {
  border-color: #6c757d !important;
}
.border-success {
  border-color: #28a745 !important;
}
.border-info {
  border-color: #17a2b8 !important;
}
.border-warning {
  border-color: #ffc107 !important;
}
.border-danger {
  border-color: #dc3545 !important;
}
.border-light {
  border-color: #f8f9fa !important;
}
.border-dark {
  border-color: #343a40 !important;
}
.border-white {
  border-color: #fff !important;
}
.rounded {
  border-radius: 0.25rem !important;
}
.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}
.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.d-none {
  display: none !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}
@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}
.embed-responsive-4by3::before {
  padding-top: 75%;
}
.embed-responsive-1by1::before {
  padding-top: 100%;
}
.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}
.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}
.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}
.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}
.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}
.justify-content-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}
.justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}
.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}
.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}
.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}
.align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}
.align-items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}
.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}
.align-items-baseline {
  -webkit-box-align: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}
.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}
.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}
.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}
.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}
.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}
.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}
.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}
.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}
.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}
.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}
.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}
.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}
.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-md-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-md-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-md-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-md-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}
.float-right {
  float: right !important;
}
.float-none {
  float: none !important;
}
@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.position-static {
  position: static !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.position-fixed {
  position: fixed !important;
}
.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  -webkit-clip-path: none;
  clip-path: none;
}
.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.mw-100 {
  max-width: 100% !important;
}
.mh-100 {
  max-height: 100% !important;
}
.m-0 {
  margin: 0 !important;
}
.mt-0,
.my-0 {
  margin-top: 0 !important;
}
.mr-0,
.mx-0 {
  margin-right: 0 !important;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.ml-0,
.mx-0 {
  margin-left: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.mt-3,
.my-3 {
  margin-top: 1rem !important;
}
.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}
.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}
.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}
.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}
.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}
.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}
.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.pr-0,
.px-0 {
  padding-right: 0 !important;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}
.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}
.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}
.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}
.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}
.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.pt-3,
.py-3 {
  padding-top: 1rem !important;
}
.pr-3,
.px-3 {
  padding-right: 1rem !important;
}
.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}
.pl-3,
.px-3 {
  padding-left: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}
.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}
.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}
.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.pt-5,
.py-5 {
  padding-top: 3rem !important;
}
.pr-5,
.px-5 {
  padding-right: 3rem !important;
}
.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}
.pl-5,
.px-5 {
  padding-left: 3rem !important;
}
.m-auto {
  margin: auto !important;
}
.mt-auto,
.my-auto {
  margin-top: auto !important;
}
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}
.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.text-justify {
  text-align: justify !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.font-weight-light {
  font-weight: 300 !important;
}
.font-weight-normal {
  font-weight: 400 !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.font-italic {
  font-style: italic !important;
}
.text-white {
  color: #fff !important;
}
.text-primary {
  color: #007bff !important;
}
a.text-primary:focus,
a.text-primary:hover {
  color: #0062cc !important;
}
.text-secondary {
  color: #6c757d !important;
}
a.text-secondary:focus,
a.text-secondary:hover {
  color: #545b62 !important;
}
.text-success {
  color: #28a745 !important;
}
a.text-success:focus,
a.text-success:hover {
  color: #1e7e34 !important;
}
.text-info {
  color: #17a2b8 !important;
}
a.text-info:focus,
a.text-info:hover {
  color: #117a8b !important;
}
.text-warning {
  color: #ffc107 !important;
}
a.text-warning:focus,
a.text-warning:hover {
  color: #d39e00 !important;
}
.text-danger {
  color: #dc3545 !important;
}
a.text-danger:focus,
a.text-danger:hover {
  color: #bd2130 !important;
}
.text-light {
  color: #f8f9fa !important;
}
a.text-light:focus,
a.text-light:hover {
  color: #dae0e5 !important;
}
.text-dark {
  color: #343a40 !important;
}
a.text-dark:focus,
a.text-dark:hover {
  color: #1d2124 !important;
}
.text-muted {
  color: #6c757d !important;
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.visible {
  visibility: visible !important;
}
.invisible {
  visibility: hidden !important;
}
@media print {
  *,
  ::after,
  ::before {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn-header) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  blockquote,
  pre {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  img,
  tr {
    page-break-inside: avoid;
  }
  h2,
  h3,
  p {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  /* .container {
    min-width: 992px !important;
  } */
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
}
.wsmenu > .wsmenu-list > li > ul.sub-menu {
  opacity: 0;
  visibility: hidden;
  -o-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -o-transition: -o-transform 0.3s, opacity 0.3s;
  -ms-transition: -ms-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform: rotateX(-75deg);
  -o-transform: rotateX(-75deg);
  -moz-transform: rotateX(-75deg);
  -webkit-transform: rotateX(-75deg);
}
.wsmenu > .wsmenu-list > li:hover > ul.sub-menu {
  opacity: 1;
  visibility: visible;
  transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg);
}
.wsmenu > .wsmenu-list > li > .wsmegamenu {
  opacity: 0;
  visibility: hidden;
  -o-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -o-transition: -o-transform 0.3s, opacity 0.3s;
  -ms-transition: -ms-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform: rotateX(-75deg);
  -o-transform: rotateX(-75deg);
  -moz-transform: rotateX(-75deg);
  -webkit-transform: rotateX(-75deg);
}
.wsmenu > .wsmenu-list > li:hover > .wsmegamenu {
  opacity: 1;
  visibility: visible;
  transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg);
}
.wsmenu-list > li > .wsshoptabing {
  opacity: 0;
  visibility: hidden;
  -o-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -o-transition: -o-transform 0.3s, opacity 0.3s;
  -ms-transition: -ms-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform: rotateX(-75deg);
  -o-transform: rotateX(-75deg);
  -moz-transform: rotateX(-75deg);
  -webkit-transform: rotateX(-75deg);
}
.wsmenu-list > li:hover .wsshoptabing {
  opacity: 1;
  visibility: visible;
  transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg);
}
.wsmenu html,
.wsmenu body,
.wsmenu iframe,
.wsmenu h1,
.wsmenu h2,
.wsmenu h3,
.wsmenu h4,
.wsmenu h5,
.wsmenu h6 {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  font-family: Helvetica, sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
  font-smoothing: antialiased;
  font-smooth: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: subpixel-antialiased;
  font-smoothing: subpixel-antialiased;
  font-smooth: subpixel-antialiased;
}
.wsmenu .cl {
  clear: both;
}
.wsmenu img,
object,
embed,
video {
  border: 0 none;
  max-width: 100%;
}
.wsmenu a:focus {
  outline: 0;
}
.wsmenu:before,
.wsmenu:after {
  content: "";
  display: table;
}
.wsmenu:after {
  clear: both;
}
.headerfull {
  width: 100%;
  position: relative;
  -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
}
.smllogo {
  padding: 9px 0 0 16px;
  margin: 0;
  float: left;
  width: 6%;
}
.smllogo a {
  display: block;
  float: left;
  padding: 0;
}
.wsmain {
  width: 100%;
  margin: 0 auto;
  padding: 0 25px;
  max-width: 1300px;
}
.wsmenu {
  width: 100%;
  float: left;
  font-family: Helvetica, sans-serif;
  color: #fff;
  position: relative;
  font-size: 13px;
  padding: 0;
  display: block;
  margin: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}
.wsmenu > .wsmenu-list {
  text-align: left;
  margin: 0 auto;
  width: 100%;
  display: block;
  padding: 0;
  position: relative;
}
.wsmenu > .wsmenu-list > li {
  display: block;
  float: left;
  padding: 0;
  margin: 0;
}
.wsmenu > .wsmenu-list > li > .navtext {
  line-height: 16px;
  text-align: right;
  padding: 14px 20px;
  margin: 0;
}
.wsmenu > .wsmenu-list > li > a {
  display: block;
  text-decoration: none;
  position: relative;
}
.wsmenu > .wsmenu-list > li > .navtext > span + span:after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  content: "\f078";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 9px;
  line-height: 10px;
  margin-left: 0;
  position: relative;
  right: -7px;
  text-align: right;
  top: 1px;
}
.wsmenu > .wsmenu-list > li > .navtext > span {
  display: block;
  font-size: 11px;
  text-align: left;
  white-space: nowrap;
  width: 100%;
  line-height: 16px;
}
.wsmenu > .wsmenu-list > li > .navtext > span + span {
  white-space: nowrap;
  font-size: 12px;
  font-weight: bold;
}
.wsmenu > .wsmenu-list > li a:hover .arrow:after {
  border-top-color: #b3b3b3;
}
.wsmenu > .wsmenu-list > li a.active .arrow:after {
  border-top-color: #b3b3b3;
}
.wsmenu > .wsmenu-list > li:hover > a .arrow:after {
  border-top-color: #b3b3b3;
}
.wsmenu > .wsmenu-list > li.wssearchbar {
  width: 40%;
  float: left;
  padding-left: 15px;
}
.wsmenu > .wsmenu-list > li.wssearchbar > .topmenusearch {
  float: right;
  width: 100%;
  height: 42px;
  position: relative;
  margin: 9px 0 0 0;
}
.wsmenu > .wsmenu-list > li.wssearchbar > .topmenusearch > .searchicon {
  -webkit-transition: all 0.7s ease 0s;
  -moz-transition: all 0.7s ease 0s;
  -o-transition: all 0.7s ease 0s;
  transition: all 0.7s ease 0s;
}
.wsmenu > .wsmenu-list > li.wssearchbar > .topmenusearch > input {
  width: 100%;
  position: relative;
  float: right;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  padding: 0;
  margin: 0;
  text-indent: 15px;
  font-size: 12px;
  height: 42px;
  z-index: 2;
  outline: 0;
  color: #7d7d7d;
  -webkit-transition: all 0.7s ease 0s;
  -moz-transition: all 0.7s ease 0s;
  -o-transition: all 0.7s ease 0s;
  transition: all 0.7s ease 0s;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.wsmenu
  > .wsmenu-list
  > li.wssearchbar
  > .topmenusearch
  > input:focus
  ~ .btnstyle {
  color: #fff;
  opacity: 0.9;
}
.wsmenu
  > .wsmenu-list
  > li.wssearchbar
  > .topmenusearch
  > input:focus
  ~ .searchicon {
  opacity: 1;
  z-index: 3;
  color: #fff;
}
.wsmenu > .wsmenu-list > li.wssearchbar > .topmenusearch > .btnstyle {
  top: 0;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 49px;
  line-height: 30px;
  z-index: 1;
  cursor: pointer;
  color: #fff;
  z-index: 1000;
  border: solid 0;
  -webkit-transition: all 0.7s ease 0s;
  -moz-transition: all 0.7s ease 0s;
  -o-transition: all 0.7s ease 0s;
  transition: all 0.7s ease 0s;
  -webkit-border-radius: 0 2px 2px 0;
  -moz-border-radius: 0 2px 2px 0;
  border-radius: 0 2px 2px 0;
}
.wsmenu > .wsmenu-list > li.wssearchbar > .topmenusearch > .btnstyle > i {
  line-height: 38px;
  margin: 0;
  padding: 0;
  text-align: center;
  color: #9c9c9c;
}
.wsmenu > .wsmenu-list > li > ul.sub-menu {
  position: absolute;
  top: 60px;
  z-index: 1000;
  margin: 0;
  padding: 0;
  min-width: 190px;
  background-color: #fff;
  border: solid 1px #eee;
}
.wsmenu > .wsmenu-list > li > ul.sub-menu > li {
  position: relative;
  margin: 0;
  padding: 0;
  display: block;
}
.wsmenu > .wsmenu-list > li > ul.sub-menu > li > a {
  background-image: none;
  color: #666;
  border-right: 0 none;
  text-align: left;
  display: block;
  line-height: 22px;
  padding: 8px 12px;
  text-transform: none;
  font-size: 13px;
  letter-spacing: normal;
  border-right: 0 solid;
}
.wsmenu > .wsmenu-list > li > ul.sub-menu > li > a:hover {
  text-decoration: none;
}
.wsmenu > .wsmenu-list > li > ul.sub-menu > li > a > i {
  margin-right: 9px;
  font-size: 14px;
}
.wsmenu > .wsmenu-list > li.wsshopmyaccount {
  float: right;
}
.wsmenu > .wsmenu-list > li.wsshopmyaccount > a {
  display: block;
  line-height: 59px;
  text-decoration: none;
  position: relative;
}
.wsmenu > .wsmenu-list > .wsshopmyaccount > a i {
  display: inline-block;
  font-size: 13px;
  line-height: inherit;
  margin-right: 11px;
  vertical-align: middle;
}
.wsmenu > .wsmenu-list > li.wscarticon {
  float: right;
  clear: right;
}
.wsmenu > .wsmenu-list > li.wscarticon > a {
  display: block;
  color: #828181;
  padding: 0 26px 0 22px;
  line-height: 59px;
  text-decoration: none;
  position: relative;
}
.wsmenu > .wsmenu-list > li.wscarticon .hidetxt {
  display: none;
}
.wsmenu > .wsmenu-list > li.wscarticon a {
  padding: 0;
  text-align: center;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.wsmenu > .wsmenu-list > li.wscarticon a i {
  font-size: 16px;
}
.wsmenu > .wsmenu-list > li.wscarticon em.roundpoint {
  position: absolute;
  top: 14px;
  right: 14px;
  width: 17px;
  height: 17px;
  background-color: #da4b38;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  font-size: 9px;
  text-align: center;
  font-style: normal;
  line-height: 16px;
  color: #fff;
}
.wsmenu > .wsmenu-list > li.wscarticon i {
  display: inline-block;
  font-size: 13px;
  line-height: inherit;
  margin-right: 2px;
  color: #7b7b7b;
}
.wsmenu > .wsmenu-list > li > .wsshoptabing {
  width: 100%;
  text-align: left;
  margin: 0;
  padding: 0;
  position: absolute;
  background-color: #f8f8f8;
  left: 0;
  border-bottom: solid 2px #376cb7;
  border-top: solid 0;
  z-index: 101;
}
.wsmenu > .wsmenu-list > li > .wsshoptabing > .wsshopwp {
  width: 50%;
  display: block;
  position: relative;
  background-color: #f5f5f5;
}
.wsmenu > .wsmenu-list > li > .wsshoptabing.wtsdepartmentmenu {
  background: #fff;
  border-bottom: solid 1px #d1d3d4;
}
.wsmenu > .wsmenu-list > li > .wsshoptabing > .wsshopwp > .wstabitem {
  display: block;
  margin: 0;
  padding: 0;
  font-size: 12px;
}
.wsmenu
  > .wsmenu-list
  > li
  > .wsshoptabing
  > .wsshopwp
  > .wstabitem
  > li:before,
.wsmenu
  > .wsmenu-list
  > li
  > .wsshoptabing
  > .wsshopwp
  > .wstabitem
  > li:after {
  content: "";
  display: table;
}
.wsmenu
  > .wsmenu-list
  > li
  > .wsshoptabing
  > .wsshopwp
  > .wstabitem
  > li:after {
  clear: both;
}
#header23 .wsmenu > .wsmenu-list > li > .wsshoptabing > .wsshopwp > .wstabitem > li {
  width: 100%;
  list-style: none;
  font-family: Inter, Sans-serif !important;
  font-weight: 500;
  letter-spacing: 0.02rem;
}
#header23 .wsmenu > .wsmenu-list > li > .wsshoptabing > .wsshopwp > .wstabitem > li > a {
  display: block;
  padding: 15px 11px;
  font-size: 13px;
  color: #606060;
  border-bottom: solid 1px #e5e5e5;
  width: 85%;
  float: left;
  position: relative;
  border-left: 3px solid #fff;
  background-color: #fff;
}
.wsmenu
  > .wsmenu-list
  > li
  > .wsshoptabing
  > .wsshopwp
  > .wstabitem
  > li:hover
  a {
  text-decoration: none;
}
.wsmenu
  > .wsmenu-list
  > li
  > .wsshoptabing
  > .wsshopwp
  > .wstabitem
  > li:hover
  > a {
  text-decoration: none;
}
.wsmenu
  > .wsmenu-list
  > li
  > .wsshoptabing
  > .wsshopwp
  > .wstabitem
  > li
  > a
  > i {
  color: #848484;
  margin-right: 5px;
  text-align: center;
  width: 24px;
  font-size: 16px;
}
.wsmenu
  > .wsmenu-list
  > li
  > .wsshoptabing
  > .wsshopwp
  > .wstabitem
  > li
  > .wstitemright {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #f5f5f5;
  color: #000;
  display: block;
  float: left;
  padding: 10px 8px 0;
  width: 57%;
  min-height: 400px;
}
.wsmenu
  > .wsmenu-list
  > li:hover
  > .wsshoptabing
  > .wsshopwp
  > .wstabitem
  > li.wsshoplink-active
  > .wstitemright {
  opacity: 1;
  visibility: visible;
}
.carousel-caption {
  width: 100%;
  display: block;
  text-align: center;
  right: 0;
  left: 0;
  margin: 0;
  padding: 0;
  bottom: 0;
}
.carousel-caption h3 {
  width: 100%;
  display: block;
  padding: 7px 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.wsmenu > .wsmenu-list > li .wstbootslider {
  float: right;
  margin-top: 8px;
}
.wsmenu > .wsmenu-list > li .wstmegamenucolr {
  display: block;
  float: right;
  margin-top: 8px;
}
.wsmenu > .wsmenu-list > li .wstheading {
  width: 100%;
  display: block;
  padding: 10px 0 6px;
  font-size: 12px;
  font-weight: bold;
  color: #333;
  border-bottom: solid 1px #d9d9d9;
  margin-bottom: 12px;
  text-transform: uppercase;
}
.wsmenu > .wsmenu-list > li .wstliststy01 {
  padding: 0 0 8px;
  margin: 0;
}
.wsmenu > .wsmenu-list > li .wstliststy01 li {
  width: 33.33%;
  float: left;
  line-height: 23px;
  font-size: 14px;
  list-style: none;
  padding: 0;
  margin: 0;
}
.wsmenu > .wsmenu-list > li .wstliststy01 li a {
  color: #6e6e6e;
  font-size: 12px;
}
.wsmenu > .wsmenu-list > li .wstliststy01 li a:hover {
  color: #000;
  text-decoration: none;
}
.wsmenu > .wsmenu-list > li .wstliststy02 {
  padding: 5px 0 10px;
  margin: 0;
  text-align: left;
}
#header23 .wsmenu > .wsmenu-list > li .wstliststy02 li {
  width: 100%;
  list-style: none;
  line-height: 25px;
}
#header23 .wsmenu > .wsmenu-list > li .wstliststy02 li a {
  color: #6e6e6e;
  font-size: 12px;
}
#header23 .wsmenu > .wsmenu-list > li .wstliststy02 li a:hover {
  color: #000;
  text-decoration: none;
}
#header23 .wsmenu > .wsmenu-list > li .wstliststy02 li.wstheading {
  line-height: normal;
  padding-left: 0;
  margin-top: 0;
  text-transform: none;
}
#header23 .wsmenu > .wsmenu-list > li .wstliststy02 li a .wstcount {
  font-size: 10px;
  color: #adadad;
}
.wsmenu > .wsmenu-list > li .wstliststy06 li.wstheading {
  line-height: normal;
  padding-left: 0;
}
.wsmenu > .wsmenu-list > li .wstliststy06 {
  padding: 0;
  margin: 0;
}
.wsmenu > .wsmenu-list > li .wstliststy06 li {
  width: 100%;
  list-style: none;
  line-height: 24px;
}
.wsmenu > .wsmenu-list > li .wstliststy06 li a {
  color: #6e6e6e;
  font-size: 12px;
}
.wsmenu > .wsmenu-list > li .wstliststy06 li a:hover {
  color: #000;
  text-decoration: none;
}
.wsmenu > .wsmenu-list > li .wstliststy06 li.wstheading {
  line-height: normal;
}
.wsmenu > .wsmenu-list > li .wstliststy06 li a .wstcount {
  font-size: 10px;
  color: #adadad;
}
.wsmenu > .wsmenu-list > li .wstliststy03 {
  width: 100%;
  padding: 0 0 8px;
  margin: 0;
}
.wsmenu > .wsmenu-list > li .wstliststy03 li {
  width: 33.33%;
  float: left;
  line-height: 23px;
  font-size: 14px;
  list-style: none;
  padding: 0;
  margin: 0;
}
.wsmenu > .wsmenu-list > li .wstliststy03 li a {
  color: #6e6e6e;
  font-size: 12px;
}
.wsmenu > .wsmenu-list > li .wstliststy03 li a:hover {
  color: #000;
  text-decoration: none;
}
.wsmenu > .wsmenu-list > li .wstliststy04 {
  padding: 14px 0 0;
  margin: 0;
}
.wsmenu > .wsmenu-list > li .wstliststy04 li {
  width: 100%;
  list-style: none;
  line-height: 22px;
}
.wsmenu > .wsmenu-list > li .wstliststy04 li a {
  color: #6e6e6e;
  font-size: 12px;
}
.wsmenu > .wsmenu-list > li .wstliststy04 li a:hover {
  color: #000;
  text-decoration: none;
}
.wsmenu > .wsmenu-list > li .wstliststy04 li.wstheading {
  line-height: normal;
  text-align: center;
  padding: 12px 0;
  font-size: 13px;
  margin-bottom: 12px;
}
.wsmenu > .wsmenu-list > li .wstliststy04 li a .wstcount {
  font-size: 10px;
  color: #adadad;
}
.wsmenu > .wsmenu-list > li .wstliststy05 {
  padding: 10px 0 0;
  margin: 0;
}
.wsmenu > .wsmenu-list > li .wstmegamenucolr03 {
  padding-top: 10px;
  padding-left: 20px;
}
.wsmenu > .wsmenu-list > li .wstliststy05 li {
  width: 100%;
  list-style: none;
  line-height: 22px;
}
.wsmenu > .wsmenu-list > li .wstliststy05 li a {
  color: #6e6e6e;
  font-size: 12px;
}
.wsmenu > .wsmenu-list > li .wstliststy05 li a:hover {
  color: #000;
  text-decoration: none;
}
.wsmenu > .wsmenu-list > li .wstliststy05 li.wstheading {
  line-height: normal;
}
.wsmenu > .wsmenu-list > li .wstliststy05 li a .wstcount {
  font-size: 10px;
  color: #adadad;
}
.wsmenu > .wsmenu-list > li .wstmorebtn {
  border-radius: 2px;
  color: #9b9b9b;
  display: inline-block;
  float: right;
  font-size: 10px;
  font-weight: normal;
  letter-spacing: 0;
  padding: 1px 7px;
  text-align: right;
  text-transform: none;
}
.wsmenu > .wsmenu-list > li .wstmenutag {
  height: 18px;
  line-height: 18px;
  text-align: center;
  font-size: 11px;
  color: #fff;
  border-radius: 2px;
  position: relative;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
  padding: 1px 6px;
  margin-left: 6px;
  text-transform: none;
  letter-spacing: -0px;
}
.wsmenu > .wsmenu-list > li .wstmenutag:after {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-image: none;
  border-style: solid;
  border-width: 3px;
  content: "";
  left: -6px;
  margin-top: 0;
  position: absolute;
  top: 4px;
  z-index: 1;
}
.wsmenu > .wsmenu-list > li .wstmenutag.redtag {
  background-color: #fe7b8f;
}
.wsmenu > .wsmenu-list > li .wstmenutag.redtag:after {
  border-color: transparent #fe7b8f transparent transparent;
}
.wsmenu > .wsmenu-list > li .wstmenutag.greentag {
  background-color: #00c853;
}
.wsmenu > .wsmenu-list > li .wstmenutag.greentag:after {
  border-color: transparent #00c853 transparent transparent;
}
.wsmenu > .wsmenu-list > li .wstmenutag.bluetag {
  background-color: #4fc3f7;
}
.wsmenu > .wsmenu-list > li .wstmenutag.bluetag:after {
  border-color: transparent #4fc3f7 transparent transparent;
}
.wsmenu > .wsmenu-list > li .wstmenutag.orangetag {
  background-color: #fe7000;
}
.wsmenu > .wsmenu-list > li .wstmenutag.orangetag:after {
  border-color: transparent #fe7000 transparent transparent;
}
/* .wsmenu > .wsmenu-list > li .kitchenmenuimg {
  background-image: url(../images/kitchen-menu-img.png);
  background-position: bottom right;
  background-repeat: no-repeat;
}
.wsmenu > .wsmenu-list > li .computermenubg {
  background-image: url(../images/computer-menu-img.png);
  background-position: bottom right;
  background-repeat: no-repeat;
} */
.wsmenu > .wsmenu-list > li .wstpngsml {
  padding-left: 18px;
}
.wsmenu > .wsmenu-list > li .wstfullwtag {
  width: 100%;
  display: block;
  border-bottom: solid 1px #e5e5e5;
  background-color: #f5f5f5;
}
.wsmenu > .wsmenu-list > li .wstbrandbottom {
  width: 100%;
  background-color: #fff;
  display: block;
  opacity: 0;
  position: absolute;
  right: 0;
  visibility: hidden;
  padding: 9px 6px;
}
.wsmenu-list
  > li:hover
  > .wtsbrandmenu
  > .wsshoptabingwp
  > .wstabitem02
  > li.wsshoplink-active
  > .wstbrandbottom {
  opacity: 1;
  visibility: visible;
}
.wsmenu > .wsmenu-list > li .wstabitem02 {
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  display: table;
}
.wsmenu > .wsmenu-list > li .wstabitem02 > li {
  display: table-cell;
  list-style: outside none none;
  text-align: center;
}
.wsmenu > .wsmenu-list > li .wstabitem02 > li > a {
  display: block;
  padding: 14px 0;
  font-size: 13px;
  color: #717171;
  background-color: #f5f5f5;
  border-bottom: 1px solid #e5e5e5;
  position: relative;
}
#header23 .wsmenu > .wsmenu-list > li .wstabitem02 > .wsshoplink-active > a:after {
  position: absolute;
  content: "";
  top: 42px;
  right: 50%;
  width: 13px;
  height: 13px;
  transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
  -moz-transform: rotate(225deg);
  -o-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  border-right: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
  z-index: 100;
  background-color: #fff;
}
.wsmenu > .wsmenu-list > li .wstabitem02 > li:hover a {
  text-decoration: none;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}
#header23 .wsmenu > .wsmenu-list > li .wstabitem02 > .wsshoplink-active > a {
  text-decoration: none;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
  background-color: #f5f5f5;
  color: #333;
}
#header23 .wsmenu > .wsmenu-list > li .wstabitem02 > .wsshoplink-active > a > i {
  opacity: 1;
}
.wsmenu > .wsmenu-list > li .wstabitem02 > li:hover a {
  text-decoration: none;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}
.wsmenu > .wsmenu-list > li .wstabitem02 > li > a i {
  margin-right: 5px;
  text-align: center;
  width: 25px;
  font-size: 17px;
  opacity: 0.5;
}
.wsmenu > .wsmenu-list > li .brandcolor01 {
  color: #424242;
}
.wsmenu > .wsmenu-list > li .brandcolor02 {
  color: #00bcf2;
}
.wsmenu > .wsmenu-list > li .brandcolor03 {
  color: #00aff0;
}
.wsmenu > .wsmenu-list > li .brandcolor04 {
  color: #003087;
}
.wsmenu > .wsmenu-list > li .brandcolor05 {
  color: #a82400;
}
.wsmenu > .wsmenu-list > li .brandcolor06 {
  color: #f30;
}
.wsmenu > .wsmenu-list > li .brandcolor07 {
  color: #7ac142;
}
.wsmenu > .wsmenu-list > li .brandcolor08 {
  color: #ef4056;
}
.wsmenu > .wsmenu-list > li > .wsmegamenu {
  width: 100%;
  left: 0;
  position: absolute;
  color: #000;
  z-index: 1000;
  margin: 0;
  text-align: left;
  padding: 14px 5px;
  font-size: 15px;
  border: solid 1px #eee;
  background-color: #fff;
}
.wsmenu > .wsmenu-list > li > .wsmegamenu .title {
  border-bottom: 1px solid #ccc;
  font-size: 14px;
  padding: 9px 5px 9px 0;
  font-size: 17px;
  color: #424242;
  margin: 0 0 7px;
  text-align: left;
  height: 39px;
}
.wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li {
  display: block;
  text-align: center;
  white-space: nowrap;
  text-align: left;
}
.wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li a {
  line-height: 18px;
  border-right: 0;
  text-align: left;
  padding: 6px 0;
  background: #fff;
  background-image: none;
  color: #666;
  border-right: 0 none;
  display: block;
  background-color: #fff;
  color: #424242;
}
.wsmenu > .wsmenu-list > li > .wsmegamenu li i {
  margin-right: 5px;
  text-align: center;
  width: 18px;
}
.wsmenu > .wsmenu-list > li > .wsmegamenu li a:hover {
  background: transparent;
  text-decoration: underline;
}
.wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li i {
  font-size: 11px;
}
.wsmenu > .wsmenu-list > li > .wsmegamenu li i {
  margin-right: 5px;
  text-align: center;
  width: 18px;
}
.wsmenu > .wsmenu-list > li > .wsmegamenu .mrgtop {
  margin-top: 15px;
}
.wsmenu > .wsmenu-list > li > .wsmegamenu .show-grid div {
  padding-bottom: 10px;
  padding-top: 10px;
  background-color: #dbdbdb;
  border: 1px solid #e7e7e7;
  color: #6a6a6a;
  margin: 2px 0;
  font-size: 13px;
}
.wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu {
  width: 40%;
  right: auto;
  left: auto;
}
.wsmenu > .wsmenu-list > li > .wsmegamenu.halfdiv {
  width: 35%;
  right: 0;
  left: auto;
}
.wsmobileheader {
  display: none;
}
.overlapblackbg {
  opacity: 0;
  visibility: hidden;
}
.wsmenu .wsmenu-click {
  display: none;
}
.wsmenu .wsmenu-click02 {
  display: none;
}
.wsmenu .wsmenu-click03 {
  display: none;
}
.hometext {
  display: none;
}
@media only screen and (max-width: 991px) {
  html {
    overflow: hidden;
    height: 100%;
    -webkit-overflow-scrolling: touch;
  }
  body {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
  body.wsactive {
    overflow: hidden;
  }
  .smllogo {
    display: none;
  }
  .wsmain {
    margin: 0;
    background-color: transparent;
  }
  .wsmenu {
    width: 100%;
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    left: 0;
    overflow-y: hidden;
    padding: 0;
    top: 0;
    visibility: hidden;
    position: fixed;
    margin: 0;
    border-left: none;
  }
  .wsmenu > .wsmenu-list {
    height: auto;
    min-height: 100%;
    width: 260px;
    background: #fff;
    padding-bottom: 0;
    margin-left: -290px;
    display: block;
    text-align: center;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    position: static;
  }
  .wsmenu > .wsmenu-list > li {
    width: 100%;
    display: block;
    float: none;
    border-right: 0;
    background-color: transparent;
    position: relative;
    white-space: inherit;
  }
  @supports (-webkit-overflow-scrolling: touch) {
    .wsmenu > .wsmenu-list > li:last-child {
      padding-bottom: 110px;
    }
  }
  .wsmenu > .wsmenu-list > li > a {
    padding: 9px 32px 9px 18px;
    font-size: 14px;
    text-align: left;
    border-right: solid 0;
    background-color: transparent;
    color: #666;
    line-height: 25px;
    border-bottom: 1px solid;
    position: static;
  }
  .wsmenu > .wsmenu-list > li > .navtext {
    padding-left: 18px;
  }
  .wsmenu > .wsmenu-list > li.wscarticon a {
    padding-left: 18px;
  }
  .wsmenu > .wsmenu-list > li.wsshopmyaccount > a {
    padding-left: 18px;
  }
  .wsmenu > .wsmenu-list > li > a > i {
    font-size: 16px;
    color: #bfbfbf;
  }
  .wsmenu > .wsmenu-list > li.wscarticon a i {
    margin-right: 7px;
    font-size: 15px;
  }
  .wsmenu > .wsmenu-list > li > a .wsarrow:after {
    display: none;
  }
  .wsmenu > .wsmenu-list > li.wscarticon em.roundpoint {
    display: inline-block;
    right: auto;
    left: 27px;
  }
  .wsmenu > .wsmenu-list > li:hover > a {
    background-color: rgba(0, 0, 0, 0.08);
    text-decoration: none;
  }
  .wsmenu > .wsmenu-list > li > a > .hometext {
    display: inline-block;
  }
  .wsmenu > .wsmenu-list > li.wscarticon .hidetxt {
    display: inline-block;
  }
  .wsmenu > .wsmenu-list > li.wssearchbar {
    display: none;
  }
  .wsmenu > .wsmenu-list > li.wscarticon {
    float: none;
  }
  .wsmenu > .wsmenu-list > li.wsshopmyaccount {
    float: none;
  }
  .wsmenu > .wsmenu-list > li.wscarticon a {
    text-align: left;
    font-size: 13px;
  }
  .wsmenu > .wsmenu-list > li > ul.sub-menu {
    display: none;
    position: relative;
    top: 0;
    background-color: #fff;
    border-bottom: solid 1px #ccc;
    padding: 0;
    opacity: 1;
    visibility: visible;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
    -webkit-transition: inherit;
    -moz-transition: inherit;
    transition: inherit;
    -webkit-transform-style: flat;
  }
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a {
    line-height: 20px;
    font-size: 13px;
    padding: 13px 0 13px 16px;
    color: #6e6e6e;
    border-bottom: solid 1px rgba(0, 0, 0, 0.13);
  }
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li span + a {
    padding-right: 30px;
  }
  .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a:hover {
    background-color: #e7e7e7;
    color: #666;
    text-decoration: underline;
  }
  .wsmenu > .wsmenu-list > li > ul.sub-menu li:hover > a {
    background-color: #e7e7e7;
    color: #666;
  }
  .wsmenu > .wsmenu-list > li > .wsmegamenu {
    background-color: #fff;
    padding-top: 5px;
    color: #666;
    display: none;
    position: relative;
    top: 0;
    padding: 8px 0;
    border: solid 0;
    opacity: 1;
    visibility: visible;
    transform: none;
    -o-transform: none;
    -moz-transform: none;
    -webkit-transform: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);
    -webkit-transform-style: flat;
  }
  .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu {
    width: 100%;
  }
  #header23 .wstabitem02 > .wsshoplink-active > a:after {
    display: none;
  }
  #header23 .wsmenu > .wsmenu-list > li > .wsshoptabing > .wsshopwp > .wstabitem > li {
    position: relative;
  }
  .wsmenu
    > .wsmenu-list
    > li
    > .wsshoptabing
    > .wsshopwp
    > .wstabitem
    > li
    > a {
    width: 100%;
    margin: 0;
    float: none;
    font-size: 12px;
    padding: 14px 11px;
    font-weight: bold;
  }
  .wsmenu > .wsmenu-list > li .wstheading {
    font-weight: normal;
    padding-left: 0;
    padding-right: 0;
  }
  .wsmenu
    > .wsmenu-list
    > li
    > .wsshoptabing
    > .wsshopwp
    > .wstabitem
    > li
    > .wstitemright {
    width: 100%;
    position: static;
    top: 0;
    min-height: inherit;
    padding: 10px 0;
    opacity: 1;
    visibility: visible;
    display: none;
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);
  }
  .wsmenu > .wsmenu-list > li .wstbootslider {
    width: auto;
  }
  .wsmenu > .wsmenu-list > li .wstmegamenucolr {
    width: auto;
  }
  .wsmenu > .wsmenu-list > li .wstliststy01 > li {
    width: 100%;
    padding: 0;
    margin: 0;
  }
  .wsmenu > .wsmenu-list > li .wstliststy02 {
    width: 100%;
    padding: 0;
    margin: 0 0 15px 0;
  }
  .wsmenu > .wsmenu-list > li .wstliststy03 li {
    width: 100%;
  }
  .wsmenu > .wsmenu-list > li .wstliststy05 {
    width: 100%;
    padding: 0;
    margin-bottom: 10px;
  }
  .wsmenu > .wsmenu-list > li .kitchenmenuimg {
    background-image: none;
  }
  .wsmenu > .wsmenu-list > li .computermenubg {
    background-image: none;
  }
  .wsmenu > .wsmenu-list > li .wstbrandbottom {
    width: 100%;
    position: static;
    top: 0;
    min-height: inherit;
    padding: 10px 5px 15px;
    opacity: 1;
    visibility: visible;
    display: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);
  }
  .wsmenu > .wsmenu-list > li .wstabitem02 {
    display: block;
  }
  .wsmenu > .wsmenu-list > li .wstabitem02 > li {
    position: relative;
    text-align: left;
    display: block;
  }
  .wsmenu > .wsmenu-list > li .wstabitem02 > li > a {
    padding: 13px 11px;
  }
  .wsmenu > .wsmenu-list > li .wstabitem02 > li > a {
    background-color: #fff;
  }
  .wsmenu > .wsmenu-list > li .wstheading {
    padding-left: 0;
  }
  .wsmenu-list > li > .navtext {
    margin: 0;
  }
  .wsmenu .wsmenu-list > li .wsshoptabingwp {
    background-color: #fff;
    padding-left: 0;
    padding-right: 0;
  }
  .wsmenu > .wsmenu-list > li > .wsshoptabing {
    background-color: #fff;
    color: #666;
    display: none;
    position: relative;
    top: 0;
    padding: 0;
    border: solid 0;
    opacity: 1;
    visibility: visible;
    transform: none;
    -o-transform: none;
    -moz-transform: none;
    -webkit-transform: none;
    -webkit-transform-style: flat;
  }
  .wsmenu > .wsmenu-list > li > .wsshoptabing > .wsshopwp > .wstabitem {
    width: 100%;
    background-color: #fff;
  }
  .wsmenu
    > .wsmenu-list
    > li
    > .wsshoptabing
    > .wsshopwp
    > .wstabitem
    > li
    > a:after {
    border: 0;
  }
  .wsmenu
    > .wsmenu-list
    > li
    > .wsshoptabing
    > .wsshopwp
    > .wstabitem
    > li
    > a
    i {
    display: none;
  }
  .wsmobileheader {
    width: 100%;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10002;
    height: 60px;
    background-color: #eaecf0;
    text-align: center;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  }
  .wsactive .wsmobileheader {
    top: 0 !important;
    margin-left: 260px;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
  }
  .wsmobileheader > .smllogo {
    display: block;
    width: 80px;
    margin: 7px auto 0 auto;
    float: none;
    padding-left: 0;
  }
  .mobiletext {
    display: inline-block;
  }
  .wsmobileheader .wssearch {
    background-color: transparent;
    z-index: 1000;
    position: absolute;
    top: 0;
    right: 0;
    padding: 18px 22px;
    cursor: pointer;
  }
  .wsmobileheader .wssearch i {
    font-size: 18px;
    color: #9196a0;
  }
  .wsmobileheader .wssearchform {
    display: none;
    position: absolute;
    width: 100%;
    height: 50px;
    line-height: 44px;
    top: 0;
    left: 0;
    padding: 13px 15px;
    cursor: default;
  }
  .wsmobileheader .wssearch.wsopensearch {
    width: 100%;
    background-color: #000;
  }
  .wsmobileheader .wssearch.wsopensearch .wssearchform {
    display: block;
  }
  .wsmobileheader .wssearchform form {
    width: calc((100% - 10px) - 45px);
    display: block;
    position: relative;
  }
  .wsmobileheader .wssearchform form:before {
    content: "\f002";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    left: 12px;
    position: absolute;
    top: 0;
    color: #777;
    height: 34px;
    line-height: 36px;
    font-size: 15px;
  }
  .wsmobileheader .wssearchform input {
    width: 100%;
    height: 34px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    border: solid 0;
    text-indent: 33px;
    margin: 0;
    line-height: 18px;
    padding: 0;
    display: block;
  }
  .wsmobileheader .wssearch.wsopensearch {
    left: 0;
    right: auto;
    width: 100%;
    height: 60px;
  }
  .wsmobileheader .wssearch i.wsclosesearch {
    display: none;
  }
  .wsmobileheader .wssearch.wsopensearch i.wsclosesearch {
    display: inline-block;
    float: right;
    font-size: 23px;
  }
  .wsmobileheader .wssearch.wsopensearch i.wsopensearch {
    display: none;
  }
  .wsanimated-arrow {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 102;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  .wsanimated-arrow {
    cursor: pointer;
    padding: 16px 35px 16px 0;
    margin: 12px 0 0 15px;
  }
  .wsanimated-arrow span,
  .wsanimated-arrow span:before,
  .wsanimated-arrow span:after {
    cursor: pointer;
    height: 2px;
    width: 17px;
    background: #9196a0;
    position: absolute;
    display: block;
    content: "";
  }
  .wsanimated-arrow span:before {
    top: -7px;
    width: 23px;
  }
  .wsanimated-arrow span:after {
    bottom: -7px;
    width: 17px;
  }
  .wsanimated-arrow span,
  .wsanimated-arrow span:before,
  .wsanimated-arrow span:after {
    transition: all 500ms ease-in-out;
  }
  .wsactive .wsanimated-arrow span:after {
    width: 23px;
  }
  .wsactive .wsanimated-arrow span {
    background-color: transparent;
  }
  .wsactive .wsanimated-arrow span:before,
  .wsactive .wsanimated-arrow.active span:after {
    top: 7px;
  }
  .wsactive .wsanimated-arrow span:before {
    transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    bottom: 0;
  }
  .wsactive .wsanimated-arrow span:after {
    transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  .wsmenu > .overlapblackbg {
    right: 0;
    width: calc(100% - 260px);
    height: 100vh;
    min-height: 100%;
    position: fixed;
    top: 0;
    opacity: 0;
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.45);
    cursor: pointer;
  }
  .wsactive .wsmenu > .overlapblackbg {
    opacity: 1;
    visibility: visible;
    -webkit-transition: opacity 1.5s ease-in-out;
    -moz-transition: opacity 1.5s ease-in-out;
    -ms-transition: opacity 1.5s ease-in-out;
    -o-transition: opacity 1.5s ease-in-out;
  }
  .wsmenucontainer {
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
  }
  .wsactive .wsmenucontainer {
    margin-left: 260px;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
  }
  .wsactive .wsmenu {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    visibility: visible;
    z-index: 1000;
    top: 0;
  }
  .wsactive .wsmenu > .wsmenu-list {
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    margin-left: 0;
  }
  .wsmenu > .wsmenu-list > li > .wsmenu-click {
    border-left: 1px solid;
    cursor: pointer;
    display: block;
    height: 60px;
    position: absolute;
    right: 0;
    top: 0;
    width: 49px;
    z-index: 10;
  }
  .wsmenu > .wsmenu-list > li > .wsmenu-click > i {
    display: block;
    height: 8px;
    width: 8px;
    float: right;
    transform: rotate(-225deg);
    margin: 23px 21px 0 0;
  }
  .wsmenu > .wsmenu-list > li > .wsmenu-click > i:before {
    content: "";
    width: 100%;
    height: 100%;
    border-width: 1.5px 1.5px 0 0;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.4);
    transition: 0.2s ease;
    display: block;
    transform-origin: 100% 0;
  }
  .wsmenu > .wsmenu-list > li > .wsmenu-click.ws-activearrow > i {
    transform: rotate(-45deg);
    margin-top: 27px;
  }
  .wsmenu
    .wsmenu-list
    > li
    > .wsshoptabing
    > .wsshopwp
    > .wstabitem
    > li
    > .wsmenu-click02 {
    border-left: 1px solid #f3f3f3;
    cursor: pointer;
    display: block;
    height: 45px;
    position: absolute;
    right: 0;
    top: 0;
    width: 49px;
    z-index: 10;
    background-color: #f5f5f5;
  }
  .wsmenu
    .wsmenu-list
    > li
    > .wsshoptabing
    > .wsshopwp
    > .wstabitem
    > li
    > .wsmenu-click02
    i {
    display: block;
    height: 8px;
    width: 8px;
    float: right;
    transform: rotate(-225deg);
    margin: 17px 21px 0 0;
  }
  .wsmenu
    .wsmenu-list
    > li
    > .wsshoptabing
    > .wsshopwp
    > .wstabitem
    > li
    > .wsmenu-click02
    i:before {
    content: "";
    width: 100%;
    height: 100%;
    border-width: 1.5px 1.5px 0 0;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.4);
    transition: 0.2s ease;
    display: block;
    transform-origin: 100% 0;
  }
  .wsmenu
    .wsmenu-list
    > li
    > .wsshoptabing
    > .wsshopwp
    > .wstabitem
    > li
    > .wsmenu-click02.ws-activearrow02
    > i {
    transform: rotate(-45deg);
    margin-top: 21px;
  }
  .wsmenu
    .wsmenu-list
    > li
    > .wtsbrandmenu
    > .wsshoptabingwp
    > .wstabitem02
    > li
    > .wsmenu-click03 {
    border-left: 1px solid #f3f3f3;
    cursor: pointer;
    display: block;
    height: 45px;
    position: absolute;
    right: 0;
    top: 0;
    width: 49px;
    z-index: 10;
    background-color: #f3f3f3;
  }
  .wsmenu
    .wsmenu-list
    > li
    > .wtsbrandmenu
    > .wsshoptabingwp
    > .wstabitem02
    > li
    > .wsmenu-click03
    i {
    display: block;
    height: 8px;
    width: 8px;
    float: right;
    transform: rotate(-225deg);
    margin: 17px 21px 0 0;
  }
  .wsmenu
    .wsmenu-list
    > li
    > .wtsbrandmenu
    > .wsshoptabingwp
    > .wstabitem02
    > li
    > .wsmenu-click03
    i:before {
    content: "";
    width: 100%;
    height: 100%;
    border-width: 1.5px 1.5px 0 0;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.4);
    transition: 0.2s ease;
    display: block;
    transform-origin: 100% 0;
  }
  .wsmenu
    .wsmenu-list
    > li
    > .wtsbrandmenu
    > .wsshoptabingwp
    > .wstabitem02
    > li
    > .wsmenu-click03.ws-activearrow03
    > i {
    transform: rotate(-45deg);
    margin-top: 21px;
  }
  .wsmenu > .wsmenu-list > li > .wsshoptabing > .wsshopwp {
    width: 100%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1262px) {
  .wsmenu > .wsmenu-list > li.wssearchbar {
    width: 21%;
  }
  .smllogo {
    width: 7%;
  }
  .wsmenu {
    width: 100%;
  }
}
@media only screen and (min-width: 1300px) {
}
@media only screen and (min-width: 1200px) and (max-width: 1299px) {
  .wsmenu > .wsmenu-list > li.wsshopmyaccount > a {
    padding: 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .wsmenu > .wsmenu-list > li.wsshopmyaccount > a {
    padding: 0;
  }
}
@media only screen and (max-width: 991px) {
  .headerfull {
    height: 60px;
  }
  .wsmenu > .wsmenu-list > li > .navtext {
    padding: 22px 20px;
  }
  div#headerBib {
    top: 85px !important;
  }
  .top28 {
    top: 25px !important;
  }
}
body {
  background-color: #fff;
}
.wrapper {
  max-width: 1550px;
  margin: 0 auto;
  padding: 0;
  min-width: 320px;
}
.header {
  width: 100%;
  display: block;
  min-height: 214px;
  padding-top: 0;
}
.headerwp {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 25px;
}
.posrlt {
  position: relative;
}
.headtoppart {
  display: block;
  width: 100%;
  background-color: #f8f8f8;
  height: 39px;
  margin: 0 auto;
  padding: 0;
  min-width: 320px;
}
#header23 div.headertopleft.clearfix a {
  color: #fff;
}
.headertopleft {
  float: right;
}
.headertopright {
  float: left;
  text-align: left;
}
.headertopright a {
  line-height: 39px;
  color: #5f5f5f;
  font-size: 13px;
  padding: 0 12px;
  display: inline-block;
  float: left;
}
.headertopright a:first-child {
  font-size: 14px;
}
.headertopright a:last-child {
  font-size: 12px;
}
.headertopright a:hover {
  background-color: #272b37;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.headertopright a.facebookicon:hover {
  background-color: #3b5998;
  color: #fff;
}
.headertopright a.twittericon:hover {
  background-color: #55acee;
  color: #fff;
}
.headertopright a.linkedinicon:hover {
  background-color: #0077b5;
  color: #fff;
}
.headertopright a.googleicon:hover {
  background-color: #dd4b39;
  color: #fff;
}
.address {
  padding: 10px 0 0;
  font-size: 12px;
  color: #b6b6b6;
}
.address a {
  color: #838383;
}
.address span {
  margin-right: 9px;
  color: #838383;
}
.address i {
  margin-right: 6px;
  margin-left: 6px;
  color: #5f5f5f;
}
.norlt {
  position: static;
}
.menuwrapper {
  position: relative;
}
.show-grid {
  background-color: #fff;
}
.btn-header-border {
  border: 2px solid;
  border-radius: 40px;
  display: inline-block;
  font-size: 13px;
  text-transform: uppercase;
  -moz-user-select: none;
  background-image: none;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  color: #fff;
  padding: 15px 40px;
}
.btn-header-border:hover {
  color: #fff;
  text-decoration: none;
  opacity: 0.9;
}
.btn-header-border:focus {
  color: #fff;
  text-decoration: none;
  opacity: 0.9;
}
.moreoption {
  width: 100%;
  display: block;
  margin-top: 290px;
}
.moreoption .boxheader {
  border: solid 0;
  padding-top: 25px;
  padding-bottom: 10px;
  font-size: 16px;
  color: #717171;
}
.mobiletext02 {
  display: none;
}
.buttoncolor {
  color: #fff;
  display: block;
  font-size: 13px;
  padding: 10px 15px;
  text-decoration: none;
  transition: background 0.3s linear 0s;
  width: 12.5%;
  height: 30px;
  float: left;
  text-align: center;
  margin: 0;
}
.buttoncolor:hover {
  color: #fff;
  text-decoration: none;
}
.buttoncolor:focus {
  color: #fff;
  text-decoration: none;
}
.buttoncolor03 {
  color: #fff;
  display: block;
  font-size: 13px;
  padding: 10px 15px;
  text-decoration: none;
  transition: background 0.3s linear 0s;
  width: 12.5%;
  height: 30px;
  float: left;
  text-align: center;
  margin: 0;
}
.buttoncolor03:hover {
  color: #fff;
  text-decoration: none;
}
.buttoncolor03:focus {
  color: #fff;
  text-decoration: none;
}
.headertlt {
  width: 100%;
  padding: 40px 0 15px;
  letter-spacing: -0.5px;
  font-size: 22px;
  font-family: "archivo_narrowregular";
  color: #424242;
}
.colorlink01 {
  width: 40%;
  float: left;
  margin: 0 0 0 2%;
}
.colorlink02 {
  width: 40%;
  float: left;
  margin: 0 0 0 2%;
}
.colorlink03 {
  width: 14%;
  float: left;
  margin: 0 0 0 2%;
}
.buttoncolor02 {
  color: #424242;
  display: block;
  font-size: 15px;
  border-radius: 2px;
  height: 30px;
  padding: 10px 15px;
  text-decoration: none;
  transition: background 0.3s linear 0s;
  width: 100%;
  border-right: solid 5px #fff;
  float: left;
  text-align: center;
  margin: 0;
  background-color: #f2f2f2;
  border: solid 1px #d9d9d9;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  width: 50%;
  float: left;
}
.buttoncolor02:hover {
  background-color: #424242;
  color: #000;
}
.buttoncolor02.active {
  background-color: #424242;
  color: #fff;
}
.main-content {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.swichermainbx {
  max-width: 1200px;
  margin: 0 auto;
}
.swichermainleft {
  width: 50%;
  float: left;
  margin-bottom: 25px;
}
.swichermainright {
  width: 50%;
  float: left;
  margin-bottom: 25px;
}
.swichertxt02 {
  font-size: 15px;
  margin-bottom: 15px;
}
.wscolorcode:hover {
  color: #fff;
  text-decoration: none;
}
.wscolorcode {
  width: 12.5%;
  margin: 0;
  color: #fff;
  float: left;
  padding: 4px 12px;
  margin: 0;
  height: 30px;
  line-height: 22px;
  background-color: #333;
  font-size: 13px;
  text-transform: uppercase;
  text-align: center;
  border-bottom: solid 2px #fff;
}
.wscolorcode.cblack {
  width: 16.66%;
}
.wscolorcode.active {
  border-bottom: solid 2px #333;
  opacity: 1;
}
.wscolorcode.cblack.active {
  border-bottom: solid 2px #da4b38;
  opacity: 1;
}
.wscolorcode i {
  font-size: 12px;
  color: #fff;
}
.wscolorcode.cblue {
  background-color: #1991eb;
}
.wscolorcode.corange {
  background-color: #fb872b;
}
.wscolorcode.cred {
  background-color: #d7412e;
}
.wscolorcode.cpink {
  background-color: #eb65a0;
}
.wscolorcode.cgreen {
  background-color: #80c133;
}
.wscolorcode.cpurple {
  background-color: #967adc;
}
.wscolorcode.cgry {
  background-color: #ccc;
}
.wscolorcode.cyellow {
  background-color: #ffd452;
}
.wscolorcode.grd-green {
  background: -webkit-gradient(
    linear,
    0% 0,
    0% 100%,
    from(#49c4a4),
    to(#97b832)
  );
  background: -webkit-linear-gradient(left, #49c4a4, #97b832);
  background: -moz-linear-gradient(left, #49c4a4, #97b832);
  background: -o-linear-gradient(left, #49c4a4, #97b832);
  background: -ms-linear-gradient(left, #49c4a4, #97b832);
  background: linear-gradient(left, #49c4a4, #97b832);
  background-color: #49c4a4;
}
.wscolorcode.grd-blue {
  background: -webkit-gradient(
    linear,
    0% 0,
    0% 100%,
    from(#31c8b1),
    to(#4ec7ff)
  );
  background: -webkit-linear-gradient(left, #31c8b1, #4ec7ff);
  background: -moz-linear-gradient(left, #31c8b1, #4ec7ff);
  background: -o-linear-gradient(left, #31c8b1, #4ec7ff);
  background: -ms-linear-gradient(left, #31c8b1, #4ec7ff);
  background: linear-gradient(left, #31c8b1, #4ec7ff);
  background-color: #4ec7ff;
}
.wscolorcode.grd-red {
  background: -webkit-gradient(
    linear,
    0% 0,
    0% 100%,
    from(#d90647),
    to(#eb402c)
  );
  background: -webkit-linear-gradient(left, #d90647, #eb402c);
  background: -moz-linear-gradient(left, #d90647, #eb402c);
  background: -o-linear-gradient(left, #d90647, #eb402c);
  background: -ms-linear-gradient(left, #d90647, #eb402c);
  background: linear-gradient(left, #d90647, #eb402c);
  background-color: #d90647;
}
.wscolorcode.grd-light-green {
  background: -webkit-gradient(
    linear,
    0% 0,
    0% 100%,
    from(#29bb92),
    to(#69cbb0)
  );
  background: -webkit-linear-gradient(left, #29bb92, #69cbb0);
  background: -moz-linear-gradient(left, #29bb92, #69cbb0);
  background: -o-linear-gradient(left, #29bb92, #69cbb0);
  background: -ms-linear-gradient(left, #29bb92, #69cbb0);
  background: linear-gradient(left, #29bb92, #69cbb0);
  background-color: #29bb92;
}
.wscolorcode.grd-pink {
  background: -webkit-gradient(
    linear,
    0% 0,
    0% 100%,
    from(#c659a7),
    to(#fd67a0)
  );
  background: -webkit-linear-gradient(left, #c659a7, #fd67a0);
  background: -moz-linear-gradient(left, #c659a7, #fd67a0);
  background: -o-linear-gradient(left, #c659a7, #fd67a0);
  background: -ms-linear-gradient(left, #c659a7, #fd67a0);
  background: linear-gradient(left, #c659a7, #fd67a0);
  background-color: #cc48d4;
}
.wscolorcode.grd-orange {
  background: -webkit-gradient(
    linear,
    0% 0,
    0% 100%,
    from(#ff5183),
    to(#ff754b)
  );
  background: -webkit-linear-gradient(left, #ff5183, #ff754b);
  background: -moz-linear-gradient(left, #ff5183, #ff754b);
  background: -o-linear-gradient(left, #ff5183, #ff754b);
  background: -ms-linear-gradient(left, #ff5183, #ff754b);
  background: linear-gradient(left, #ff5183, #ff754b);
  background-color: #ff5183;
}
.wscolorcode.grd-black {
  background: -webkit-gradient(
    linear,
    0% 0,
    0% 100%,
    from(#383d41),
    to(#5d6069)
  );
  background: -webkit-linear-gradient(left, #383d41, #5d6069);
  background: -moz-linear-gradient(left, #383d41, #5d6069);
  background: -o-linear-gradient(left, #383d41, #5d6069);
  background: -ms-linear-gradient(left, #383d41, #5d6069);
  background: linear-gradient(left, #383d41, #5d6069);
  background-color: #5d6069;
}
.wscolorcode.grd-gry {
  background: -webkit-gradient(linear, 0% 0, 0% 100%, from(#ccc), to(#e4e4e4));
  background: -webkit-linear-gradient(left, #ccc, #e4e4e4);
  background: -moz-linear-gradient(left, #ccc, #e4e4e4);
  background: -o-linear-gradient(left, #ccc, #e4e4e4);
  background: -ms-linear-gradient(left, #ccc, #e4e4e4);
  background: linear-gradient(left, #ccc, #e4e4e4);
  background-color: #ccc;
}
@media only screen and (min-width: 230px) and (max-width: 991px) {
  .wrapper {
    width: 100%;
    margin: 0;
  }
  .header {
    min-height: 75px;
  }
  .logo {
    display: none;
  }
  .header {
    box-shadow: none;
    background-image: none;
  }
  .colorlink01 {
    width: 92%;
    margin: 0 4%;
  }
  .colorlink02 {
    width: 92%;
    margin: 0 4%;
  }
  .colorlink03 {
    width: 92%;
    margin: 0 4%;
  }
  .buttoncolor02 {
    width: 100%;
    margin-bottom: 2%;
  }
  .buttoncolor {
    width: 25%;
  }
  .buttoncolor03 {
    width: 25%;
  }
  .moreoption {
    width: 100%;
    margin: 85px 0 0;
    background-color: transparent;
    border: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  .headtoppart {
    margin-top: 0;
    height: auto;
    background-color: rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
  }
  .topmenusearch {
    margin: 0;
    width: 100%;
  }
  .topmenusearch input:focus {
    width: 100%;
  }
  .posrlt {
    width: 240px;
    float: none;
  }
  .headerright {
    width: 100%;
    float: none;
  }
  .headertopleft {
    float: none;
    width: 96%;
    margin: 0 2%;
  }
  .headertopright {
    float: none;
    width: 96%;
    margin: 0 2%;
  }
  .address.clearfix:before,
  .address.clearfix:after {
    content: "";
    display: table;
  }
  .address.clearfix:after {
    clear: both;
  }
  .address.clearfix {
    zoom: 1;
  }
  .headertopleft {
    display: none;
  }
  .headerfull {
    height: auto;
  }
  .headertopright a {
    width: 25%;
    text-align: center;
    font-size: 15px;
  }
  .headertopright a:first-child {
    font-size: 16px;
  }
  .headertopright a:last-child {
    font-size: 13px;
  }
  .swichermainbx {
    width: 96%;
    margin: 0 2%;
    display: block;
    background-color: rgba(255, 255, 255, 0.3);
    padding: 15px 25px;
    border-radius: 14px;
  }
  .swichermainleft {
    width: 98%;
    margin: 1% 1%;
  }
  .swichermainright {
    width: 98%;
    margin: 1% 1%;
  }
  .wscolorcode {
    width: 12.5%;
  }
  .swichertxt {
    display: none;
  }
  .swichertxt02 span {
    display: none;
  }
}
div#headerBib {
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 10000;
  box-shadow: 0 0 4px rgb(0 0 0 / 16%);
}
.containerHeaderBib {
  padding: 5px;
  margin: 0 auto;
}
@media (min-width: 992px) {
  .containerHeaderBib {
    max-width: 970px;
  }
}
@media (min-width: 1200px) {
  .containerHeaderBib {
    max-width: 1170px;
  }
}
@media (min-width: 1300px) {
  .containerHeaderBib {
    max-width: 1270px;
  }
}
.smllogo img#imgHeaderMobile {
  width: 150px;
}
header {
  padding: 0 15px;
}
.columns {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 5px 0;
  align-items: center;
}
.column {
  flex: 1;
  margin: 2px;
  padding: 10px;
}
.column.column1 {
  margin-left: 0;
  flex-grow: 1;
}
.column.column2 {
  flex-grow: 2;
}
.column.column3 {
  margin-right: 0;
  flex-grow: 1;
}
.column.column3 i {
  font-size: 24px;
}
form#searchBarHeader2023 {
  width: 100%;
  height: 42px;
  position: relative;
}
form#searchBarHeader2023 input {
  width: 100%;
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  padding: 0;
  margin: 0;
  text-indent: 15px;
  font-size: 12px;
  height: 42px;
  z-index: 2;
  outline: 0;
  color: #7d7d7d;
  border: 1px solid lightgray;
}
form#searchBarHeader2023 button {
  top: 0;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 49px;
  line-height: 30px;
  z-index: 1;
  cursor: pointer;
  color: #fff;
  z-index: 1000;
  border: solid 0;
  border-top: 1px solid lightgray;
  border-right: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
}
.wsmenu > .wsmenu-list > .wsshopmyaccount > a i#userIconHeader {
  font-size: 24px;
  margin: 0 auto;
}
.wsmenu > .wsmenu-list > .wsshopmyaccount > a i#ordineRapidoIconHeader {
  font-size: 24px;
  margin: 0 auto;
}
.wsmenu > .wsmenu-list > .wsshopmyaccount > a i#preferitiIconHeader {
  font-size: 24px;
  margin: 0 auto;
}
.wsmenu > .wsmenu-list > li.wscarticon a i#chartIconHeader {
  font-size: 24px;
  margin: 0 auto;
}
@media screen and (max-width: 991px) {
  .wsmobileheader > .smllogo {
    margin-left: 50px;
  }
  .columns .column {
    margin-bottom: 5px;
    flex-basis: 50%;
  }
  .column.column1,
  .column.column3 {
    display: none;
  }
  .column.column1 img {
    width: 60%;
  }
  .column.column2 {
    order: 3;
    flex-basis: 100%;
  }
  .columns .column.column3 {
    margin-right: 0;
    margin: 0;
    flex-basis: 20%;
  }
}
@media screen and (max-width: 680px) {
  .columns .column {
    margin: 0 0 5px 0;
  }
}
.wsmenu > .wsmenu-list > li > .navtext > span {
  color: #fff;
}
@media screen and (max-width: 991px) {
  .headerwp {
    height: 25px;
  }
}
.wsmenu > .wsmenu-list > li#primoItemMenu > .navtext > span {
  color: #fff;
}
.wsmenu > .wsmenu-list > li#primoItemMenu:hover > a {
  background-color: #00953b;
  color: #fff;
}
.wsmenu {
  font-family: "Raleway", sans-serif;
}
.wsmenu > .wsmenu-list > li > .navtext > span {
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.6px;
}
#header23 .formLoginHeaderPopup {
  position: relative;
  z-index: 1;
  margin: 0 auto 0;
  padding: 15px;
  text-align: center;
  width: 225px;
}
#header23 .formLoginHeaderPopup h2 {
  color: #000;
  font-weight: 700;
  font-size: 16px;
}
#header23 .formLoginHeaderPopup h2 + p {
  color: #000;
}
#header23 .formLoginHeaderPopup input {
  font-family: "Inter", sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 7px;
  box-sizing: border-box;
  font-size: 12px;
}
#header23 .formLoginHeaderPopup button {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: #4caf50;
  width: 100%;
  border: 0;
  padding: 6px;
  color: #fff;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
}
#header23 .formLoginHeaderPopup button:hover,
#header23 .form button:active,
#header23 .form button:focus {
  background: #43a047;
}
#header23 .formLoginHeaderPopup .message {
  margin: 15px 0 0;
  color: #b3b3b3;
  font-size: 12px;
}
#header23 .formLoginHeaderPopup .message a {
  color: #4caf50;
  text-decoration: none;
}
div.viewAllSubMega.viewAllSubMega.clearfix {
  border-bottom: 0;
}
#header23 .wsmenu > .wsmenu-list > li > .wsshoptabing > .wsshopwp > .wstabitem > li > a {
  padding-top: 9px;
  padding-bottom: 9px;
}
@media only screen and (max-width: 991px) {
  .wsmobileheader .wsmobileIconBib {
    background-color: transparent;
    z-index: 1000;
    position: absolute;
    top: 0;
    right: 0;
    padding: 18px 22px;
    cursor: pointer;
  }
  .wsmobileheader .wsmobileIconBib iconify-icon {
    font-size: 24px;
    padding-right: 10px;
    color: #9196a0;
  }
  .wsmobileIconBib em.roundpoint {
    position: absolute;
    top: 14px;
    right: 25px;
    width: 17px;
    height: 17px;
    background-color: #da4b38;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    font-size: 9px;
    text-align: center;
    font-style: normal;
    line-height: 16px;
    color: #fff;
  }
  .wsmobileIconBib span.hidetxt {
    display: none;
  }
}
#ordineRapidoTooltip {
  padding: 0;
  border: 0;
}
#ordineRapidoTooltip > p {
  padding: 10px;
  margin-bottom: 0;
  background-color: #ffaf00;
}
.iconaGreenMenuHor {
  width: 20px;
  float: left;
  margin-right: 3px;
  padding-top: 5px;
}
.cart-title {
  margin-bottom: 30px;
}
#header23 .cart-info {
  font-size: 12px;
}
.cart-icon {
  cursor: pointer;
  display: inline-block;
  position: absolute;
  width: 30px;
  height: auto;
  right: 30px;
  top: 27px;
  padding: 5px;
  z-index: 40;
  opacity: 0.5;
}
.cart-icon:hover {
  opacity: 1;
}
.cart-icon svg {
  display: block;
  height: 100%;
  width: 100%;
}
.minicart-container {
  background: #fff;
  padding: 30px 15px;
  position: fixed;
  top: 0;
  right: 0;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  bottom: 0;
  z-index: 99999999;
  max-width: 450px;
  width: 100%;
  -webkit-transition: 200ms;
  -o-transition: 200ms;
  transition: 200ms;
  box-shadow: 0 0 20px rgb(0 0 0 / 20%);
}
@media (max-width: 425px) {
  .minicart-container {
    max-width: 100%;
    left: 0;
  }
}
.minicart-container.show-cart {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}
.minicart-container.hide-cart {
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
}
#header23 .minicart-container .cart-title h3 {
  border-bottom: solid 1px #3c3c3c;
  padding-bottom: 20px;
}
.minicart-container .close-cart {
  cursor: pointer;
  width: 34px;
  height: auto;
  padding: 3px 0 7px 20px;
  position: absolute;
  right: 30px;
  top: 27px;
}
.minicart-container .close-cart iconify-icon {
  font-size: 32px;
}
.minicart-container svg {
  height: auto;
  width: 100%;
}
#header23 .minicart-container .bottom-container {
  background: #fff;
  width: 100%;
  padding: 10px 30px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
}
#header23 .minicart-container .bottom-container h4 {
  border-top: solid 1px #3c3c3c;
  padding-top: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 20px;
}
#header23 .minicart-container .bottom-container p {
  position: absolute;
  right: 50px;
  top: 45px;
}
#header23 .minicart-container .bottom-container .btn-header.checkout {
  background: #61ce70;
  display: block;
  text-align: center;
  color: #fff;
  padding: 15px 10px;
  text-transform: uppercase;
  letter-spacing: 2px;
  width: 100%;
  font-size: 15px;
}
#header23 .minicart-container .bottom-container .btn-header.checkout:hover {
  background: #a96f2e;
  text-decoration: none;
}
.overlay {
  background: #1e1e1e;
  opacity: 0.4;
  -webkit-transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
  -o-transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
  display: none;
  position: fixed;
  z-index: 10001;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
#header23 .svuotaCarrelloRightCart {
  float: right;
  font-style: italic;
  font-size: 12px;
  color: #959595;
  font-family: Inter, Sans-serif !important;
  cursor: pointer;
}
#header23 .product-image {
  float: left;
  width: 20%;
}
#header23 .product-details {
  float: left;
  width: 37%;
}
#header23 .product-price {
  float: left;
  width: 12%;
}
#header23 .product-quantity {
  float: left;
  width: 10%;
}
#header23 .product-removal {
  float: left;
  width: 9%;
}
#header23 .product-line-price {
  float: left;
  width: 12%;
  text-align: right;
}
.group:before,
.shopping-cart:before,
.column-labels:before,
.product:before,
.totals-item:before,
.group:after,
.shopping-cart:after,
.column-labels:after,
.product:after,
.totals-item:after {
  content: "";
  display: table;
}
.group:after,
.shopping-cart:after,
.column-labels:after,
.product:after,
.totals-item:after {
  clear: both;
}
.group,
.shopping-cart,
.column-labels,
.product,
.totals-item {
  zoom: 1;
}
.product .product-price:before,
.product .product-line-price:before,
.totals-value:before {
  content: "€ ";
}
h1 {
  font-weight: 100;
}
label {
  color: #aaa;
}
#header23 .shopping-cart {
  margin-top: -45px;
}
.column-labels label {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #eee;
}
.column-labels .product-image,
.column-labels .product-details,
.column-labels .product-removal {
  text-indent: -9999px;
}
.product {
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}
.product .product-image {
  text-align: center;
}
.product .product-image img {
  width: 100px;
}
.product .product-details .product-title {
  margin-right: 20px;
  font-family: "HelveticaNeue-Medium", "Helvetica Neue Medium";
}
.product .product-details .product-description {
  margin: 5px 20px 5px 0;
  line-height: 1.4em;
}
.product .product-quantity input {
  width: 40px;
}
.product .remove-product {
  border: 0;
  padding: 4px 8px;
  background-color: #c66;
  color: #fff;
  font-family: "HelveticaNeue-Medium", "Helvetica Neue Medium";
  font-size: 12px;
  border-radius: 3px;
}
.product .remove-product:hover {
  background-color: #a44;
}
.totals {
  margin-top: 30px;
}
.totals .totals-item {
  clear: both;
  width: 100%;
  margin-bottom: 0;
}
#header23 .totals .totals-item label {
  float: left;
  clear: both;
  width: 70%;
  text-align: right;
}
#header23 .totals .totals-item .totals-value {
  float: right;
  width: 30%;
  text-align: right;
}
#header23 .totals .totals-item-total {
  font-family: "Inter";
  font-weight: 700;
}
#header23 .checkout {
  float: right;
  border: 0;
  margin-top: 20px;
  padding: 6px 25px;
  background-color: #6b6;
  color: #fff;
  font-size: 25px;
  border-radius: 3px;
}
#header23 .checkout:hover {
  background-color: #494;
}
@media screen and (max-width: 650px) {
  #header23 .shopping-cart {
    margin: 0;
    padding-top: 20px;
    border-top: 1px solid #eee;
  }
  #header23 .column-labels {
    display: none;
  }
  #header23 .product-image {
    float: right;
    width: auto;
  }
  #header23 .product-image img {
    margin: 0 0 10px 10px;
  }
  #header23 .product-details {
    float: none;
    margin-bottom: 10px;
    width: auto;
  }
  #header23 .product-price {
    clear: both;
    width: 70px;
  }
  .product-quantity {
    width: 100px;
  }
  .product-quantity input {
    margin-left: 20px;
  }
  .product-quantity:before {
    content: "x";
  }
  .product-removal {
    width: auto;
  }
  .product-line-price {
    float: right;
    width: 70px;
  }
}
@media screen and (max-width: 350px) {
  #header23 .product-removal {
    float: right;
  }
  #header23 .product-line-price {
    float: right;
    clear: left;
    width: auto;
    margin-top: 10px;
  }
  #header23 .product .product-line-price:before {
    content: "Item Total: $";
  }
  #header23 .totals .totals-item label {
    width: 60%;
  }
  #header23 .totals .totals-item .totals-value {
    width: 40%;
  }
}
#header23 .cart-right-items-container {
  border: 1px solid red;
}
#header23 .itemCartRight {
  display: flex;
}
#header23 .itemCartRight .product-noiva-right-cart {
  float: left;
  color: #6b6;
}
#header23 .itemCartRight .product-quantity2 {
  width: auto;
  float: right;
  color: #9e9e9e;
  margin-right: 35px;
}
#header23 .itemCartRight .product-quantity2 input {
  width: 40px;
  border: 1px solid #e9e9e9;
  outline: 0;
}
.imgitemCartRight,
.revenueitemCartRight {
  height: 60px;
  width: 60px;
}
#header23 .itemCartRight img {
  width: 50px;
}
.revenueitemCartRight {
  padding-top: 5px;
  text-align: right;
}
.dataitemCartRight {
  width: 300px;
}
.dataitemCartRight > div {
  padding-right: 1px;
}
.item + .item {
  margin-left: 2%;
}
.product-removal {
  float: right;
  line-height: 37px;
  margin-left: 20px;
  font-size: 28px;
  color: red;
  cursor: pointer;
}
@media screen and (max-width: 400px) {
  .dataitemCartRight {
    width: 250px;
  }
  #header23 .product-title-right-cart {
    width: 250px;
  }
  .totals {
    margin-top: 0;
  }
}
#slider_textsli,
#slider_textsli .slide_textsli {
  width: 500px;
}
#slider_textsli {
  overflow: hidden;
  margin: 0 auto;
  font-size: 12px;
  margin-left: 0;
}
#slider_textsli .container_textsli {
  position: relative;
  width: 9000px;
  top: 0;
  right: 0;
   margin-top: 10px; 
}
#slider_textsli .slide_textsli {
  position: relative;
  float: left;
  box-sizing: border-box;
  padding: 0 20px;
}
#slider_textsli p {
  margin-bottom: 0;
}
@keyframes slide-animation {
  0% {
    opacity: 0;
    right: 0;
  }
  11% {
    opacity: 1;
    right: 0;
  }
  22% {
    right: 100%;
  }
  33% {
    right: 100%;
  }
  44% {
    right: 200%;
  }
  55% {
    right: 200%;
  }
  66% {
    right: 300%;
  }
  77% {
    right: 300%;
  }
  88% {
    opacity: 1;
    right: 400%;
  }
  100% {
    opacity: 0;
    right: 400%;
  }
}
@media screen and (max-width: 991px) {
  #slider_textsli .container_textsli {
    animation: slide-animation 25s infinite;
  }
  #slider_textsli {
    line-height: 9px;
  }
  #slider_textsli p {
    font-size: 10px !important;
  }
  .mobHiddenBib {
    display: none;
  }
}
.headerfull {
  background-color: #fff;
}
.wsmenu > .wsmenu-list {
  background-color: #fff;
}
.wsmenu > .wsmenu-list > li > .navtext > span {
  color: #9e9e9e;
}
.wsmenu > .wsmenu-list > li > .navtext > span + span {
  color: #555;
}
.wsmenu > .wsmenu-list > li > .navtext > span + span:after {
  color: #9e9e9e;
}
.wsmenu > .wsmenu-list > li.wsshopmyaccount > a {
  color: #828181;
}
.wsmenu > .wsmenu-list > .wsshopmyaccount > a i {
  color: #7b7b7b;
}
.wsmenu > .wsmenu-list > li.wssearchbar > .topmenusearch > input {
  background-color: #eee;
}
.wsmenu > .wsmenu-list > li.wssearchbar > .topmenusearch > .btnstyle {
  background-color: #eee;
}
.wsmenu > .wsmenu-list > li.wssearchbar > .topmenusearch > input:focus {
  color: #000;
}
.wsmenu > .wsmenu-list > li > a {
  color: #fff;
}
.wsmenu > .wsmenu-list > li > a .wsarrow:after {
  border-top-color: #b3b3b3;
}
.wsmenu > .wsmenu-list > li > a:hover .wsarrow:after {
  border-top-color: #333;
}
.wsmenu > .wsmenu-list > li > a.active .wsarrow:after {
  border-top-color: #333;
}
.wsmenu > .wsmenu-list > li:hover > a .wsarrow:after {
  border-top-color: #333;
}
.wsmenu > .wsmenu-list > li > a > i {
  color: #898d91;
}
.wsmenu > .wsmenu-list > li > a.active i {
  color: #333;
}
.wsmenu > .wsmenu-list > li:hover > a > i {
  color: #333;
}
.wsmenu > .wsmenu-list > li > a:hover i {
  color: #333;
}
.wsmenu > .wsmenu-list > li.wssearchbar > .topmenusearch > .btnstyle:hover {
  background-color: #d7d7d7;
}
.wsmenu > .wsmenu-list > li.wssearchbar > .topmenusearch > .btnstyle:hover i {
  color: #333;
}
.wsmenu
  > .wsmenu-list
  > li.wssearchbar
  > .topmenusearch
  > input:focus
  ~ .btnstyle {
  background-color: #d7d7d7;
}
.wsmenu
  > .wsmenu-list
  > li.wssearchbar
  > .topmenusearch
  > input:focus
  ~ .btnstyle
  > i {
  color: #333;
}
.wsmenu
  > .wsmenu-list
  > li.wssearchbar
  > .topmenusearch
  > input:focus
  ~ .btnstyle
  i {
  color: #333;
}
.wsmenu > .wsmenu-list > li > a.active {
  background-color: #f5f5f5;
  color: #333;
}
.wsmenu > .wsmenu-list > li:hover > a {
  background-color: #f5f5f5;
  color: #333;
}
.wsmenu > .wsmenu-list > li > ul.sub-menu > li:hover > a {
  background-color: #f5f5f5;
  color: #333;
  text-decoration: none;
}
.wsmenu > .wsmenu-list > li > ul.sub-menu > li > ul.sub-menu > li:hover > a {
  background-color: #f5f5f5;
  color: #333;
}
.wsmenu
  > .wsmenu-list
  > li
  > .wsshoptabing
  > .wsshopwp
  > .wstabitem
  > li.wsshoplink-active
  > a {
  background-color: #f5f5f5;
  color: #555;
  border-left: 3px solid #da4b38;
}
.wsmobileheader {
  background-color: #fff;
}
.wsactive .wsmobileheader {
  border-left: solid 1px #e0e0e0;
}
.wsmenu > .wsmenu-list > li > .wsmenu-click {
  border-color: rgba(0, 0, 0, 0.1);
}
.wsmenu > .wsmenu-list > li > .wsmenu-click > i {
  color: rgba(0, 0, 0, 0.25);
}
.wsmenu > .wsmenu-list > li > a {
  border-bottom-color: rgba(0, 0, 0, 0.13);
}

.wsmenu>.wsmenu-list>li>.navtext>span {
  letter-spacing: .1px;
  font-family: "Inter", sans-serif !important;
}

.wsmenucontainer .wsmenu {
  font-family: "Inter", sans-serif !important;
  color: #555;
  font-weight: 400;
}
.headersticky {
  position: sticky;
  top: 0;
  z-index: 1900;
}
.headtoppart {
  background: linear-gradient(90deg, #00953a 0, #23b85e 100%);
  height: 36px;
}
.headerwp {
  padding-right: 0;
}
.address.clearfix .bolder {
  font-weight: 600;
  font-size: 12px;
  color: #fff;
  letter-spacing: 0.1px;
}
.address.clearfix iconify-icon {
  font-size: 14px;
  vertical-align: text-top;
  padding: 0;
}
.address.clearfix a,
.address.clearfix span,
#slider_textsli p {
  font-size: 11px;
  font-weight: 400;
  text-decoration: none;
  color: #fff;
  padding: 0 1px;
}
#slider_textsli {
  margin-left: 0;
}
#slider_textsli .slide_textsli {
  padding: 0;
}
div#headerBib {
  box-shadow: none;
}
.column.column2 {
  flex-grow: 3;
}
.column.column1 img {
  padding: 10px 0;
  width: 180px;
}
form#searchBarHeader2023 {
  height: 38px;
}
form#searchBarHeader2023 input {
  width: 100%;
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  padding: 0;
  margin: 0;
  font-family: "Inter", sans-serif;
  font-size: 13px;
  font-weight: 300;
  text-indent: 38px;
  letter-spacing: 0.3px;
  height: 38px;
  z-index: 2;
  outline: 0;
  color: #555;
  border: 1px solid #cbcbcb;
  border-radius: 5px;
}
form#searchBarHeader2023 button {
  position: absolute;
  top: -px;
  left: 0;
  width: 42px;
  z-index: 1;
  outline: 0;
  cursor: pointer;
  outline: 0;
  z-index: 1000;
  border: 0;
  background-color: transparent;
}
.column.column2 .btnstyle iconify-icon {
  color: #23b85e;
  font-size: 22px;
  vertical-align: middle;
  padding: 5px 0;
}
.wsmenu > .wsmenu-list > li > a > iconify-icon {
  color: #23b85e;
  padding: 8px;
  font-size: 28px;
  border-radius: 50%;
  float: right;
}
.wsmenu > .wsmenu-list > .wsshopmyaccount,
.wscarticon {
  background-color: rgba(0, 149, 59, 0.15);
  margin: 0 20px 0 0;
  border-radius: 50%;
}
.wsmenu > .wsmenu-list > .wsshopmyaccount:hover,
.wscarticon:hover {
  border-radius: 50%;
}
.wsmenu > .wsmenu-list > li.wscarticon > a {
  padding: 0;
}
.wsmenu > .wsmenu-list > li.wscarticon em.roundpoint {
  top: 2px;
  right: 2px;
  background-color: #f76205;
  width: 16px;
  height: 16px;
}
.wsmenu {
  border-left: 0;
}
.column3 .wsmenu-list > li {
  padding: 0;
  margin: 0;
  position: relative;
}
.wsmenu > .wsmenu-list > li > ul.sub-menu {
  position: absolute;
  top: 60px;
  z-index: 1000;
  margin: 0;
  padding: 15px 0;
  min-width: 200px;
  background-color: #fff;
  left: -140px;
  border-radius: 4px;
  border: solid 1px #d3d3d3;
  -webkit-box-shadow: 0 4px 5px 0 #00015;
  -moz-box-shadow: 0 4px 5px 0 #00015;
  -o-box-shadow: 0 4px 5px 0 #00015;
  box-shadow: 0 4px 5px 0 #00015;
}
.wsmenu > .wsmenu-list > li > ul.sub-menu > li:hover > a {
  font-family: "Inter", sans-serif !important;
  background-color: #f7f7f7;
  color: #555;
}
.wsmenu > .wsmenu-list > li > ul.sub-menu > li > a > iconify-icon {
  margin-right: 9px;
  font-size: 20px;
  color: #d2d2d2;
  text-align: left;
  vertical-align: middle;
  float: right;
}
.wsmenu > .wsmenu-list > li > ul.sub-menu > li:hover > a > iconify-iconfy {
  background-color: #f5f5f5;
  text-decoration: none;
}
.triangolo1 {
  position: absolute;
  top: -10px;
  left: 150px;
  bottom: -10px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #d3d3d3;
  -moz-transform: rotate(0);
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  -o-transform: rotate(0);
  transform: rotate(0);
}
.triangolo2 {
  position: absolute;
  top: -8.5px;
  left: 150px;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid rgba(255, 255, 255);
  -moz-transform: rotate(0);
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  -o-transform: rotate(0);
  transform: rotate(0);
}
.tooltipbox {
  position: absolute;
  z-index: 100;
  content: "";
  height: 28px;
  width: 60px;
  background-color: transparent;
  top: -20px;
  left: 140px;
  bottom: -6px;
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
#header23 .formLoginHeaderPopup input {
  font-size: 11px;
  font-family: "Inter", sans-serif;
  color: #838383;
  letter-spacing: 0.6px;
}
#header23 .formLoginHeaderPopup h2 {
  font-family: "Inter", sans-serif;
  color: #555;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  text-transform: none;
}
#header23 .formLoginHeaderPopup h2 + p {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  color: #555;
  letter-spacing: 0.2px;
}
#header23 .formLoginHeaderPopup {
  padding: 20px 15px;
  font-family: "Inter", sans-serif;
}
#header23 .formLoginHeaderPopup button {
  font-family: "Inter", sans-serif;
  background: #23b85e;
  font-weight: 600;
  text-transform: none;
  font-size: 14px;
  letter-spacing: 0.6px;
}
#header23 .formLoginHeaderPopup button:hover,
.form button:active,
.form button:focus {
  background: #2bc769;
}
#header23 .formLoginHeaderPopup .message {
  font-family: "Inter", sans-serif;
  color: #555;
}
#header23 .formLoginHeaderPopup .message a {
  font-family: "Inter", sans-serif;
  color: #23b85e;
  text-decoration: none;
}
.minicart-container {
  padding: 25px 25px;
}
.minicart-container.show-cart {
  font-family: "Inter", sans-serif;
}
#header23 .minicart-container .cart-title h3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #00953b;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 16px;
}
.minicart-container .close-cart iconify-icon {
  font-size: 36px;
  color: rgba(0, 149, 59, 0.9);
}
.minicart-container .close-cart {
  cursor: pointer;
  width: 34px;
  height: auto;
  padding: 3px 0 0 10px;
  position: absolute;
  right: 30px;
  top: 18px;
}
#header23 .cart-info {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: rgba(85, 85, 85, 0.8);
}
#header23 span.svuotaCarrelloRightCart {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  color: rgba(0, 149, 59, 0.4);
}
.imgitemCartRight {
  padding: 0;
}
.dataitemCartRight {
  width: 180px;
}
#header23 .product-title-right-cart {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 18px;
  height: 39px;
  color: #1e1e1e;
  width: auto;
}
#header23 .itemCartRight .product-noiva-right-cart {
  float: none;
  font-family: "Inter", sans-serif!important;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: rgba(85, 85, 85, 0.8);
}
.product-removal {
  float: left;
  line-height: 37px;
  font-size: 28px;
  color: rgba(0, 149, 59, 0.3);
  cursor: pointer;
  margin-left: 0 !important;
  width: auto;
}
.colprezzo {
  width: 112px;
}
#header23 .revenueitemCartRight.product-line-price2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: #1e1e1e;
  width: 65px;
}
#header23 .itemCartRight .product-quantity2 input {
  outline: 0;
  width: 69px;
  height: 37px;
  left: calc(50% - 69px / 2+114.5px);
  top: calc(50% - 37px / 2 - 192.5px);
  border: 2px solid rgba(0, 149, 59, 0.17);
  border-radius: 6px;
}
#header23 .itemCartRight .product-quantity2 input.quanti {
  font-size: 12px;
  padding: 0 6px 0 10px;
  font-weight: 500;
  color: #555;
}
#header23 .itemCartRight .product-quantity2 {
  width: 65px;
  float: left;
  margin-right: 1px;
  margin-left: 8px;
  font-family: Inter, sans-serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  color: rgba(85, 85, 85, 0.8);
}
#header23 .codicearticolo {
  font-family: Inter, Sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 10px!important;
  line-height: 14px;
  color: rgba(85, 85, 85, 0.8);
  letter-spacing: -0.02em;
}
#header23 .totals .totals-item label {
  font-family: Inter, sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  text-align: right;
  color: #555;
}
#header23 .totals .totals-item .totals-value {
  font-family: Inter, sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  text-align: right;
  color: #1e1e1e;
}
#header23 .minicart-container .bottom-container .btn-header.checkout:hover {
  font-family: "Inter", sans-serif;
  background: #00953b;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.6px;
}
#header23 .minicart-container .bottom-container .btn-header.checkout {
  font-family: "Inter", sans-serif;
  background: #23b85e;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.6px;
}
#header23 .minicart-container .bottom-container {
  padding: 10px 30px 30px 30px;
}
.wsmenu > .wsmenu-list > li > .wsmegamenu {
  width: 100%;
  padding: 0 10px;
  margin: 0;
}
.wsmenu > .wsmenu-list > li .wstliststy04 li.wstheading a {
  text-align: center;
  color: #555c3;
  font-size: 11px;
  text-decoration: none;
  letter-spacing: 0.3px;
}
.wsmenu > .wsmenu-list > li .wstliststy04 li.wstheading img {
  padding: 0 0 10px 0;
}
.wsmenu > .wsmenu-list > li > .navtext > span {
  color: #555;
}
.wsmenu > .wsmenu-list > li > .navtext.evidence > span {
  color: #ff9609;
}
.wsmenu > .wsmenu-list > li > .navtext.eco > span {
  padding: 0 10px;
}
.iconaGreenMenuHor {
  padding-top: 4px;
}
.height100 {
  height: 100%;
}
.wsmenu > .wsmenu-list > li .wstheading {
  color: #555;
  border-bottom: 0;
  padding: 4px 0 0;
  margin-bottom: 5px;
  font-weight: 600;
  letter-spacing: 0.2px;
}
.allcategory {
  color: #555;
  border-top: 1px solid #dbdcdd;
  margin: 0 10px;
}
.bannerMegaMenu > a > img {
  margin-top: 20px;
}
.bannerMegaMenu {
  position: absolute;
  bottom: 20px;
  right: 20px;
  left: 20px;
}
.wsmenu > .wsmenu-list > img .wstadsize01 {
  width: 200px;
  position: fixed;
  bottom: 20px;
}
.wsmenu
  > .wsmenu-list
  > li
  > .wsshoptabing
  > .wsshopwp
  > .wstabitem
  > li
  > .wstitemright {
  padding: 15px 8px 0 10px;
  font-weight: 400;
}
.wsmenu > .wsmenu-list > li .wstliststy02 {
  padding: 0;
}
#header23 .wsmenu > .wsmenu-list > li .wstliststy02 li {
  padding-bottom: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Inter, Sans-serif !important;
  font-weight: 500;
  letter-spacing: -0.02rem; 
}
.wsmenu > .wsmenu-list > li .wstliststy02 img {
  height: 28px;
  padding-right: 5px;
}
#header23 .wsmenu > .wsmenu-list > li .wstliststy02 li a {
  font-size: 12px;
}
.wsmenu > .wsmenu-list > li#primoItemMenu > a > span {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  word-spacing: 0.8px;
}
.wsmenu > .wsmenu-list > li#primoItemMenu > a {
  background: linear-gradient(90deg, #00953a 0, #23b85e 100%);
}
.wsmenu > .wsmenu-list > li#primoItemMenu > a > span > iconify-icon {
  color: rgba(255, 255, 255);
  font-size: 22px;
  border-radius: 50%;
  line-height: 22px;
  float: left;
  padding-right: 10px;
}
.wsmenu > .wsmenu-list > li > .wsshoptabing.wtsdepartmentmenu {
  background: #f4f4f4;
}
.wsmenu > .wsmenu-list > li > .wsshoptabing {
  width: 50%;
}
.wsmenu > .wsmenu-list > li > .wsshoptabing > .wsshopwp {
  width: 100%;
}
#header23 .wsmenu > .wsmenu-list > li > .wsshoptabing > .wsshopwp > .wstabitem > li > a {
  color: #555;
  padding: 12px 10px;
  border-left: none;
  border-bottom: 0;
}
.wsmenu
  > .wsmenu-list
  > li
  > .wsshoptabing
  > .wsshopwp
  > .wstabitem
  > li.wsshoplink-active
  > a {
  color: #555;
  border-left: none;
}
.wsmenu > .wsmenu-list > li .wstmorebtn {
  font-weight: 500;
  text-align: left;
  float: none;
  font-size: 11px;
}
.wsmenu
  > .wsmenu-list
  > li
  > .wsshoptabing
  > .wsshopwp
  > .wstabitem
  > li:hover
  > a {
  text-decoration: none;
  color: #353535;
}
#header23 .wsmenu > .wsmenu-list > li .wstliststy02 li a:hover {
  color: #353535;
}
.wsmenu > .wsmenu-list > li .wstheadingtitolo {
  color: #555;
  padding: 10px 8px 16px 15px;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.2px;
}
.wsmenu
  > .wsmenu-list
  > li:hover
  > .wsshoptabing
  > .wsshopwp
  > .wstabitem
  > li.wsshoplink-active
  > .wstitemright {
  height: 100%;
}
.wstitemright clearfix {
  height: 100%;
}
.wstitemright .wstadsize02 img {
  position: fixed;
  width: 48%;
  bottom: 100px;
}
.wstitemright .wstadsize01 img {
  position: fixed;
  width: 48%;
  bottom: 20px;
}
.smllogo img#imgHeaderMobile {
  width: 100px;
}
@media only screen and (min-width: 992px) {
  .onlyMobile {
    display: none;
  }
  .wsmenu > .wsmenu-list > li#primoItemMenu {
    width: 22%;
  }
  .wsmenu
    > .wsmenu-list
    > li
    > .wsshoptabing
    > .wsshopwp
    > .wstabitem
    > li
    > a {
    width: 44%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .wsmenu > .wsmenu-list > li > .navtext {
    padding: 10px 18px;
  }
  .wsmenu > .wsmenu-list > li > .navtext > span {
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
  }
  .wsmenu
    > .wsmenu-list
    > li
    > .wsshoptabing
    > .wsshopwp
    > .wstabitem
    > li
    > .wstitemright {
    width: 56%;
  }
  .containerHeaderBib {
    max-width: 1300px;
    padding: 5px 25px;
  }
}
@media only screen and (max-width: 991px) {

  header {
    padding: 60px 15px;
  }


  .wsmenu > .wsmenu-list > li > .wsmegamenu {
    width: 100%;
    padding: 0 0 20px 0;
  }
  .wsmenu > .wsmenu-list > li .wstliststy04 li.wstheading {
    padding: 5px 20px;
    margin: 0;
  }
  .wsactive .headersticky {
    display: none;
  }
  .wsmenu > .wsmenu-list > li > .wsshoptabing {
    width: 100%;
  }
  .wsmenu
    .wsmenu-list
    > li
    > .wsshoptabing
    > .wsshopwp
    > .wstabitem
    > li
    > .wsmenu-click02 {
    background: transparent;
    border-left: none;
  }
  .wsmenu
    > .wsmenu-list
    > li
    > .wsshoptabing
    > .wsshopwp
    > .wstabitem
    > li.wsshoplink-active
    > a {
    font-weight: 600;
  }
  .wsmenu
    > .wsmenu-list
    > li
    > .wsshoptabing
    > .wsshopwp
    > .wstabitem
    > li
    > a {
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 30px;
  }
  .wsmenu
    > .wsmenu-list
    > li
    > .wsshoptabing
    > .wsshopwp
    > .wstabitem
    > li
    > a
    > img {
    padding: 0 5px 0 2px;
  }
  .wsmenu > .wsmenu-list > li .wstheadingtitolo {
    display: none;
  }
  #header23 .wsmenu > .wsmenu-list > li .wstliststy02 li {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .wsmenu > .wsmenu-list > li .wstliststy02 img {
    display: none;
  }
  .wsmenu > .wsmenu-list > li#primoItemMenu > a > span > iconify-icon {
    display: none;
  }
  .wsmobileheader .wsmobileIconBib {
    padding: 12px 15px;
  }
  .wsmobileheader .wsmobileIconBib iconify-icon {
    font-size: 22px;
    padding: 6px;
    margin-left: 6px;
    color: rgba(0, 149, 59, 0.8);
    stroke-width: 4;
    border-radius: 50%;
    background-color: rgba(0, 149, 59, 0.15);
  }
  .wsanimated-arrow span,
  .wsanimated-arrow span::before,
  .wsanimated-arrow span::after {
    background: #00953b;
  }
  .wsmenu > .wsmenu-list > li .wstliststy02 {
    width: 100%;
    padding: 15px 0 0 20px;
    margin: 0;
  }
  .wsmenu > .wsmenu-list > li .wstheadingnopadre {
    display: none;
  }
  .wsmenu > .wsmenu-list > li .wstheadingnopadre2 {
    padding: 0;
    font-size: 12px;
  }
  .wsmenu
    > .wsmenu-list
    > li
    > .wsshoptabing
    > .wsshopwp
    > .wstabitem
    > li
    > .wstitemright {
    padding: 0 0 10px 0;
  }
  .wstheading.viewAllSubMega.clearfix.allcategory {
    display: none;
  }
  .onlyMobile {
    text-align: left;
    margin: 0;
    padding: 0;
  }
  .onlyMobile a {
    display: block;
    font-size: 12px;
    padding: 10px;
    color: #555;
    text-decoration: none;
    padding: 14px 20px;
  }
  .onlyMobile a:hover {
    font-size: 12px;
    background-color: #f5f5f5;
    text-decoration: none;
  }
  .onlyMobile > a > iconify-icon {
    font-size: 16px;
    vertical-align: middle;
    padding-right: 6px;
  }
  .iconaGreenMenuHor {
    margin-right: 4px;
    padding-top: 2px;
  }
  .container-fluid {
    padding: 0;
  }
  form#searchBarHeader2023 input {
    border-radius: 0;
    top: 10px;
    height: 38px;
    padding-left: 10px;
    font-size: 12px;
    text-indent: 26px;
    z-index: 2;
    color: #555;
    border: 1px solid #dcdcdc;
  }
  form#searchBarHeader2023 button {
    top: 12px;
    height: 38px;
  }
  .column.column2 .btnstyle iconify-icon {
    color: #00953b;
    font-size: 20px;
    padding: 0 0 6px 0;
  }
  .column.column2 {
    padding: 0;
    margin: 10px 0 0 0;
  }
  .wsactive .column.column2,
  .wsactive img#imgHeaderMobile,
  .wsactive .wsmobileIconBib {
    display: none;
  }
  .wsactive .wsmobileheader {
    width: 55px;
    height: 53px;
  }
  .wsactive .wsanimated-arrow {
    cursor: pointer;
    padding: 8px 35px 16px 0;
  }
  .wsmenu > .wsmenu-list > li > .wsmenu-click {
    border-left: none;
  }
  .wsactive .wsmenu > .wsmenu-list > li > .navtext {
    padding: 18px 20px;
  }
  .containerHeaderBib {
    padding: 0;
    z-index: 19999;
    position: absolute;
    top: 60px;
    width: 100%;
  }
  div#headerBib {
    top: 60px !important;
  }
  .wsmobileheader {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10002;
    height: 60px;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  }

  #mobileCartIconHeader23 > em  {
    position: absolute;
    top: 10px;
    right: 15px;
    width: 14px;
    height: 14px;
    background-color: #da4b38;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    font-size: 9px;
    text-align: center;
    font-style: normal;
    line-height: 14px;
    color: #fff;
  }
  .wstitemright .wstadsize01 img,
  .wstitemright .wstadsize02 img {
    display: none;
  }
}





/* =============INIZIO MP================================== */


/* ------ TESTO DA ELIMINARE------ */
.wrappercontent{
  max-width: 1300px;
padding: 5px 25px;
margin: 0 auto;
}
  /* ------ PRE-HEADER------ */
.headtoppart {
  background: linear-gradient(90deg, #00953A 0%, #23B85E 90%);

}

.address.clearfix a, .address.clearfix span, #slider_textsli p {
  font-size: 12px;
  font-weight: 500;
  color: #fff;
}

.address.clearfix .bolder {
  font-weight: 700;
  font-size: 13px;
  color: #fff;
  letter-spacing: 0.1px;
}

.wsmenu > .wsmenu-list > li#primoItemMenu > a {
  background: linear-gradient(90.03deg, #00953A 0.02%, #4ED584 99.44%);
}

.column {
  padding: 10px 0px 10px 0px;
}

.wsmenu > .wsmenu-list > li > .navtext.evidence > span {
  color: #FF5F00;
  font-weight: 500;
}
.wsmenu > .wsmenu-list > li > .navtext {
  padding: 10px 18px;
  border-right: 1px solid #e5e5e5;
  background-color: #ffffff;
}  

.wsmenu > .wsmenu-list > li.wscarticon em.roundpoint {
  background-color: #FF5F00;
}

#slider_textsli .valorizza {
  padding: 0px 5px; 
  border-right: 1px solid #ffffff95;
}

#slider_textsli > div > div:nth-child(1) > p > span.mobHiddenBib.valorizza,
span.mobHiddenBib,
#header23 > div > div.headersticky > div.headtoppart.clearfix > div > div.headertopleft.clearfix > div > span > a {
  font-family: Inter, Sans-serif !important;
}



@media only screen and (min-width: 992px) {
  .wsmenu > .wsmenu-list > li > .navtext > span {
    font-weight: 500;
  }
}


.wsmenu > .wsmenu-list > li > .navtext > span {
  letter-spacing: 0.1px;
}

.wsmenu > .wsmenu-list > li#primoItemMenu > a > span {

  font-size: 15px;
  letter-spacing: 0.1px;
}
.wsmenu > .wsmenu-list > li > a > iconify-icon {
  color: #00953A;
  font-size: 28px;
}
.column.column2 {

  padding: 0px 0px 10px 0px;
}

/* login */

#header23 .formLoginHeaderPopup h2 {
  font-size: 14px;
  text-align: left;
  font-weight: 700;
  text-transform: none;
  color: #555;
}


#header23 .formLoginHeaderPopup p {
  font-size: 12px;
  text-align: left;
  font-weight: 500;
  text-transform: none;
  line-height: 18px;
  color: #555 !important;
  margin-bottom: 0px;
  padding: 0px;
}

#header23 .formLoginHeaderPopup {
  padding: 15px 15px 0px 15px;
}

#header23 .formLoginHeaderPopup input {
  font-size: 12px;
  font-family: 'Inter',sans-serif;
  color:#555;
  letter-spacing: 0.02rem;
  margin: 12px 0px 0px 0px;
  padding: 8px;
}



#header23 .formLoginHeaderPopup .message a {
  font-family: "Inter",sans-serif;
  font-weight: 600;
  color: #00953A;
  text-decoration: none;
}

#header23 .formLoginHeaderPopup a.messagepw  {
  font-family: "Inter",sans-serif;
  font-weight: 400;
  text-transform: none;
  display: block;
  color: #00953A;
  text-decoration: none;
  padding:8px 0px 16px 0px;
}

.wsmenu > .wsmenu-list > li > ul.sub-menu > li > a {
  padding: 8px 18px;
  border-bottom: 1px solid #f7f7f7;
}

#header23 .formLoginHeaderPopup button {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.4px;
  background-color: #00953A;
  border-radius: 4px;
  line-height: 24px;
}

#header23 .formLoginHeaderPopup button:hover {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.4px;
  background: #00772E;  
}

.tooltipbox {
  width: 150px;
  height: 45px;
  top: -35px;
  left: 60px;
}


.wsmenu > .wsmenu-list > li > ul.sub-menu {

  top: 63px;
  left: -140px;
}


.tooltipbox {
  width: 150px;
}

.triangolo1 {
  left: 162px;
}

.triangolo2 {
  left: 162px;
}

form#searchBarHeader2023 input {
  width: 96%;
}

/* Cart */

.minicart-container .cart-title h3 {
  font-size: 22px;
}

#header23 .product-title-right-cart {
    color: #3e3e3e;
}

#header23 .itemCartRight .product-quantity2 {
  width: 63px;
}

#header23 .itemCartRight .product-quantity2 input {
  width: 66px;
  height: 35px;
}

#header23 .revenueitemCartRight.product-line-price2 {
    color: #3e3e3e;
    width: 65px;
  }

  #header23 .totals .totals-item {
    color: #3e3e3e;
    display: flex;
    align-items: baseline;
  }

  #header23 #cart-total.totals-value {
    font-weight: 900;
    color: #3e3e3e;

  }
  #header23 .totals .totals-item label {
    width: 75%;
  }
  
  #header23 .totals .totals-item .totals-value {
    width: 25%;
  }
  
  #header23 .totals .totals-item label::before {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 28px;
    text-align: right;
    color: #3e3e3e;

  }

.dataitemCartRight {
    width: 150px;
    padding-left: 6px;
  
  }

  #header23 .itemCartRight img {
    width: 60px;
  }

  #header23 .product-title-right-cart {
    font-size: 12px;
    font-weight: 700;
    color:#3e3e3e;
    line-height: 16px;
    height: 34px;
  }

  .colprezzo {
  padding-left: 15px;
  width: 120px;
  align-items: center;
  display: flex;
   justify-content: center;
  }

  #header23 .totals .totals-item label.totalbold {
    font-family: "Inter",sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    text-align: right;
    color: #3e3e3e;
  }
  #header23 .minicart-container .bottom-container .btn-header.checkout .btn-header.carrello {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.3px;
  }
  
  #header23 .minicart-container .bottom-container .btn-header.checkout:hover {
    font-family: "Inter",sans-serif;    
    background: #00772E;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 0.3px;
  }

  #header23 .minicart-container .bottom-container .btn-header.checkout{
    font-family: "Inter",sans-serif;
    background: #00953A;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 0.3px;
  }


  #header23 .minicart-container .bottom-container .btn-header.carrello {
    font-family: "Inter",sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    background: #fff;
    display: block;
    text-align: center;
    color: #00953A;
    border: 3px solid #00953A;
    border-radius: 4px;
    padding: 13px 10px;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    width: 100%;
  }
  #header23 .minicart-container .bottom-container .btn-header.carrello:hover {
    font-family: "Inter",sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    background: #00953A;
    border: 3px solid #00953A;
    display: block;
    text-align: center;
    color: #fff;
    padding: 13px 10px;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    width: 100%;
  }


  #header23 .itemCartRight .product-quantity2 input {
  border: 2px solid #D9D9D9;
  border-radius: 4px;;
}

.product-removal {
  color:#00953A;
  font-size: 24px;
  }

  #header23 .minicart-container .cart-title h3 {
    font-weight: 700;
    font-size: 22px!important;
    font-family: Inter, Sans-serif !important;
    letter-spacing: -0.02rem;
  }

  #header23 span.svuotaCarrelloRightCart {
    font-family: Inter, Sans-serif !important;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    color: #00953A;
  }

  #header23 .cart-info p {
    font-family: 'Inter',sans-serif!important;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    color: #555555;
  }

  #header23 .itemCartRight img {
    width: 45px;
    padding-top:8px;
  }

  #header23 .totals .totals-item label {
  font-size: 13px;
  line-height: 16px;
}
 
#header23 .totals .totals-item .totals-value {
  font-size: 13px;
  line-height: 16px;
  font-weight: 500!important;
  letter-spacing: -0.02rem!important;
}

#header23 .totals .totals-item {
  line-height: 16 px;
}

#header23 .totals .totals-item label.totalbold {
  line-height: 16px;
}

#header23 .product-title-right-cart {
  font-weight: 700;  
}

#header23 .btn-header.checkout{
  display: block;
  float: none;
  margin-top: 5px;
}
.btn-header.carrello{
  display: block; 
  float: none;
  margin-top: 15px;
}

.revenueitemCartRight {
  padding-top: 8px;

}
  .minicart-container.show-cart {
        padding: 25px 0px;
}
#header23 div.top-container,#header23 div.itemsContainer,#header23 div.bottom-container{
        padding:0px 25px;

}


  @media screen and (max-width: 425px) {
        
    .minicart-container {
        padding: 25px 20px;
      }

    .colprezzo {
      padding-left: 5px;
      width: 120px;
      align-items: center;
      display: flex;
      justify-content: center;
    }   

    .minicart-container .itemsContainer {
      max-height: 60vh;
      overflow: scroll;
    }
      }

    #header23 .revenueitemCartRight.product-line-price2 {   
      font-size: 13px;
      line-height: 33px;
      width: 80px;
      font-family: Inter, sans-serif !important;
      letter-spacing: -0.02em;
      }
      
      #header23 .revenueitemCartRight.product-line-price2::before{
        content: '€ ';
      }
      #header23 .itemCartRight .product-noiva-right-cart::before{
        content: '€ ';
      }

      #header23 .itemCartRight .product-quantity2 {
        width: 60px;
        margin-right: 1px;
        margin-left: 3px;
      }

      #header23 .itemCartRight .product-quantity2 input {
        width: 58px;
        height: 34px;
      }

      #header23 .product-title-right-cart {
  
        font-size: 12px!important; 
        font-weight: 700;  
        line-height: 16px;
        height: 34px;
        font-family: Inter, Sans-serif !important;
        letter-spacing: -0.02rem;
      }

    .dataitemCartRight {
        width: 150px;
        margin: 0px 0px 0px 0px;      
  }

  #header23 .itemCartRight .product-quantity2 {
        width: 60px;   
        margin-left: 3px;
        line-height: 16px;
      }

      #header23 .itemCartRight .product-quantity2 input.quanti {
        padding: 0 4px 0 8px;
        font-size: 13px;
      }

    .imgitemCartRight, .revenueitemCartRight {
        height: 50px;
        width: 50px;
      }

    #header23 .minicart-container .cart-title h3 {
     
        font-size: 18px;
        line-height: 24px;
        padding-bottom: 14px;
      }

    .product-removal {
        line-height: 38px;
        font-size: 24px;
        padding-bottom: 10px;
      }

      #header23 .minicart-container .itemsContainer {
        max-height: 60vh;
        overflow-y: scroll;
        overflow-x: hidden;
        padding-bottom: 100px;
    }

    #header23 .minicart-container .bottom-container  {
      padding: 0px 20px 30px 20px;
      border: 1px solid #E6E6E6;
      box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
    }

    #header23 .minicart-container .bottom-container .totals {
      border-top: 1px solid #d2d2d2;
      padding: 10px 20px 30px 20px;
   
    }
  
  
  /* Fine Cart */
  

   /* ICONE MODIFICATE */
  
  
  .wsmenu > .wsmenu-list > li > a > iconify-icon {
  color: #00953b;
  font-size: 30px;
  border-radius: unset;
  padding: 0px 15px;
  height: 40px;
}

  .wsmenu > .wsmenu-list > li > a > iconify-icon#ordineRapidoIconHeader {
    color: #00953b;
    font-size: 28px;
    padding: 0px 15px;
  
   }
 
   
.wsmenu > .wsmenu-list > .wsshopmyaccount:hover, .wscarticon:hover {
  border-radius: unset;
  background:none;
}

.wsmenu > .wsmenu-list > li.wscarticon em.roundpoint {
    top: -3px;
    right: -2px;
    background-color: #f76205;
    width: 15px;
    height: 15px;
}


.wsmenu > .wsmenu-list >.wsshopmyaccount,.wscarticon {
  background:none;
  margin: 0px 0px 0px 0px;
  padding: 0px 2px;
  border-radius: unset;
  text-align: center;
  line-height: 10px;
}

#header23 .wsmenu > .wsmenu-list > .wsshopmyaccount,.wscarticon > span.testicone {
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  font-family: "Inter", sans-serif !important;
  color: #555;
  letter-spacing: -0.02rem;

}

#userIconHeader23 > span.testicone,
#headerBib > div > section > div.column.column3 > nav > ul > li > span,
#cartIconHeader23 > span.testicone {
  font-family: "Inter", sans-serif !important;
  letter-spacing: -0.02rem;
}

.wsmenu > .wsmenu-list > .wsshopmyaccount {
  border-right: solid 1px #d2d2d2;
  height: 30px;
}

.wsmenu > .wsmenu-list > li.wsshopmyaccount > a {
  color: #333;
  display: block;
  text-align: center;
  justify-content: center;
  display: flex;
}


.wsmenu > .wsmenu-list > li.wscarticon em.roundpoint {
  right: 10px;
  cursor: pointer;
}

.column.column2 .btnstyle iconify-icon {
  color: #00953A;
}

#header23 .bottom-container .totals-item.totals-item-total{
  display: flex;
  color: #3e3e3e;
  font-weight: 900!important; 
  padding: 10px 0px 0px 0px;
  justify-content:center;
  font-family: Inter, sans-serif !important;
}

#header23 .totalbold  {
    color: #3e3e3e;
    font-weight: 900; 
    margin-right: 5px;
    font-size: 16px;

}

#header23 #cart-total-bottom{
 font-size: 16px!important;
 font-family: Inter, sans-serif !important;
 font-weight: 900!important;
 
}

.wsmenu > .wsmenu-list > li:hover > a {
  background-color: #fff;
  color: #333;
}



/* ------FINE ICONE MODIFICATE----- */



@media only screen and (max-width: 991px) {

/*  MOBILE - ICONE+HEADER Modifiche */
.wsanimated-arrow span, .wsanimated-arrow span::before, .wsanimated-arrow span::after {
  cursor: pointer;
  height: 3px;
  background: #00953a;
  position: absolute;
  display: block;
  content: '';
}

  .wsanimated-arrow {
    /* margin: 12px 0 0 15px; */
    margin: 10px 0 0 15px;
  }


  .wsmobileheader .wsmobileIconBib iconify-icon {
    color: #00953a;
    font-size: 24px;
    background: none; 
    margin: 0px;
    padding: 6px;
  }
 
  #slider_textsli .slide_textsli {
    padding: 0px;
  }
  #slider_textsli .container_textsli {
    margin-top: 0px;
  }

  .address.clearfix a, .address.clearfix span, #slider_textsli p {
    font-weight: 500;
  }

  #slider_textsli p {
    text-align: center;
    font-size: 12px !important;
    line-height: 28px;
    width: calc(100vw - 50px);
  }

  .smllogo img#imgHeaderMobile {
    width: 90px;
  }

  .headerwp {
    /* height: 25px; */
    height: 28px;
    background-color: #00953A;
 
  }

  .top28 {
    /* top: 25px !important; */
    top: 28px !important;
  }

  .wsmobileheader {
    /* height: 60px; */
    height: 55px;
  }

  form#searchBarHeader2023 input {
    /* top: 10px; */
    top: 7px;
  }

  form#searchBarHeader2023 button {
    /* top: 12px; */
    top: 8px;
  }
  form#searchBarHeader2023 input {
    width: 100%;
  }

  /*  FINE - MOBILE- ICONE+HEADER Modifiche */

}


@media only screen and (max-width: 991px) {

  .wsmenu > .wsmenu-list > li > .wsmenu-click > i {
    display: block;
    height: 12px;
    width: 12px;
    float: right;
    transform: rotate(-225deg);
    margin: 18px 21px 0 0;
  }

.wsmenu > .wsmenu-list > li > .wsmenu-click > i::before {
  border-width: 3px 3px 0 0;
  border-style: solid;
  border-color: #555555;

}

.wsmobileIconBib em.roundpoint {
  position: absolute;
  top: 10px;
  right: 15px;
  width: 14px;
  height: 14px;
  background-color: #da4b38;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  font-size: 9px;
  text-align: center;
  font-style: normal;
  line-height: 14px;
  color: #fff;
  cursor: pointer;
}

  .onlyMobile {
  background-color: #f4f4f4;
  }

  .onlyMobile a {
    /* font-size: 12px; */
    font-size: 13px;
    font-weight: 500;
  }

  .onlyMobile a:hover {
    /* font-size: 12px; */
    font-size: 13px;
    font-weight: 500;
  }

  .wsmenu > .wsmenu-list > li > .navtext > span {
    font-size: 16px;
    font-weight: 500;

  }
  .wsmenu > .wsmenu-list > li#primoItemMenu > a {
    background: #ffffff;
    color: #00953A;
  }



}




@media only screen and (min-width: 992px){

  .wsmenu > .wsmenu-list > li#primoItemMenu {
    width: 22%;
  }

  #header23 .wsmenu > .wsmenu-list > li > .wsshoptabing > .wsshopwp > .wstabitem > li > a {
    width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  #header23 .wsmenu > .wsmenu-list > li > .wsshoptabing > .wsshopwp > .wstabitem > li > .wstitemright {
    width: 50%;
  }
  #header23 .wsmenu > .wsmenu-list > li > .wsshoptabing {
    width: 50%;
  }

  .container-fluid {
    width: 100%;

  }
}  

@media only screen and (max-width: 320px) {
  .wsanimated-arrow {
    cursor: pointer;
    padding: 16px 35px 16px 0;
    margin: 12px 0 0 5px;
  }
}



/*  MENU TENDINA OTTIMIZZAZIONE ESTREMA  */

#header23 .wsmenu > .wsmenu-list > li .wstliststy02 li {
  padding-bottom: 15px;
}

#header23 .wsmenu > .wsmenu-list > li > .wsshoptabing > .wsshopwp > .wstabitem > li > a {
padding: 8px 10px;

}

#header23 .wsmenu > .wsmenu-list > .primoItemMenu > .wsmenu-click02 > img.wsshoptabing.wtsdepartmentmenu  {
  border: 0 none;
  max-width: 100%;
  width: 28px;
  padding-right: 2px;
}
#header23 .wsmenu > .wsmenu-list > li > .wsshoptabing > .wsshopwp > .wstabitem > li > a img {
width: 28px;
padding-right: 3px;
}

#header23 .wsmenu > .wsmenu-list > li > .wsshoptabing > .wsshopwp > .wstabitem > li > .wstitemright {
padding: 0px;
font-weight: 400;
}

.wsmenu > .wsmenu-list > li > .wsshoptabing > .wsshopwp {
background-color: #fff;
}

.wsmenu > .wsmenu-list > li .wstliststy02 img {
height: 25px;

}

#header23 .wsmenu > .wsmenu-list > li > .wsshoptabing > .wsshopwp > .wstabitem > li > a img {
width: unset;
padding-right: 3px;
height: 25px;
}

.wstitemright .wstadsize01 img {
position: unset;
padding-top: 5px;  
width: 100%;

}

.wsmenu > .wsmenu-list > li .wstheadingtitolo {
  color: #555;
  padding: 15px 8px 15px 15px;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: .2px;
}
.wsmenu > .wsmenu-list > li > .wsshoptabing.wtsdepartmentmenu {

  box-shadow: 0 10px 20px rgb(0 0 0 / 20%);
}
 /*----Mobile Ottimizzazione tendina----- */ 


@media only screen and (max-width: 991px) {
  
  .wsmenu > .wsmenu-list > li#primoItemMenu > .wsmenu-click > i::before {
  border-width: 3px 3px 0 0;
  border-style: solid;
  border-color: #00953a;
  }

  .wsmobileIconBib em.roundpoint {
  position: absolute;
  top: 10px;
  right: 15px;
  width: 14px;
  height: 14px;
  background-color: #da4b38;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  font-size: 9px;
  text-align: center;
  font-style: normal;
  line-height: 14px;
  color: #fff;
  cursor: pointer;
  } 

  #header23 .wsmenu > .wsmenu-list > li .wstliststy02 {
    padding: 0px 0px 0px 0px;
  }
  #header23 .wsmenu > .wsmenu-list > li .wstliststy02 li {
    padding-bottom: 0px;
  }

  #header23 .wsmenu > .wsmenu-list > li .wstliststy02 li {
    padding: 0px ;
    border-bottom: 1px solid #e6e6e6;
  }
  #header23 .wsmenu > .wsmenu-list > li .wstliststy02 li:last-child {
    border-bottom: none;
  }

  #header23 .wsmenu>.wsmenu-list>li .wstliststy02 li a {
    font-size:12px;
    display: block;
    line-height: 40px;
    padding-left: 15px;

   }
   
  .wsmenu > .overlapblackbg {
    right: 0;
    width: calc(100% - 290px);    
  }

  .wsmenu > .wsmenu-list {
    width: 290px;
  }

  .wsactive .wsmobileheader {
    margin-left: 290px;
  }

  .wsactive .wsmobileheader {
    width: 100%;
    height: 53px;
  }

  .wsmenu .wsmenu-list > li > .wsshoptabing > .wsshopwp > .wstabitem > li > .wsmenu-click02 {
  width: 100%;

  }
  .wsmenu > .wsmenu-list > li > .wsmenu-click{
  width: 100%;
  height: 55px;

  }
  #header23 .wsmenu > .wsmenu-list > li .wstliststy02 li {
  padding-bottom: 0px;
  }

  .wsmenu > .wsmenu-list > li > .wsshoptabing > .wsshopwp > .wstabitem > li > a {
  padding: 10px 10px;
  font-weight: 700;
  }

  #header23 .wsmenu > .wsmenu-list > li > .wsshoptabing > .wsshopwp > .wstabitem > li.wsshoplink-active > a {
    font-weight: 700;
  }

 .wsmenu > .wsmenu-list {
  /* background-color: #fff; */
  background: #f4f4f4;
}
  
.wsmenu > .wsmenu-list > li#primoItemMenu > a {
  background-color: #fff;
}

.wsmenu > .wsmenu-list > li#primoItemMenu > a:hover {
  background-color: #fff;
}

.wsmenu > .wsmenu-list > li#primoItemMenu > .navtext > span {
  color: #00953a;
  font-weight: 800;
  font-size: 14px;
  letter-spacing: 0.02rem;
}

.wsmobileheader .clearfix .top28 {
  background: linear-gradient(90.03deg, #00953A 0.02%, #4ED584 99.44%);
}

.wsmenu > .wsmenu-list > li > .navtext.eco > span {
  padding: 0px;
}
.wsmenu > .wsmenu-list > li > .wsshoptabing.wtsdepartmentmenu {
  box-shadow:none;
}

}



.wsmenu > .wsmenu-list > li .wstheading {
  font-weight: 700;
  letter-spacing: 0.2px;
}

.allcategory {
  margin: 0px 20px;
}

.wsmenu > .wsmenu-list > li .wstmorebtn {
  font-weight: 600;
  color: #555;
  letter-spacing: 0.05em;
  padding: 3px 0px;
}

.wsmenu > .wsmenu-list > li > .wsshoptabing {
  border-top: solid 1px #ebecee;
  z-index: 8;
}




@media only screen and (min-width: 992px) {
  #header23 .wsmenu > .wsmenu-list > li > .wsshoptabing > .wsshopwp > .wstabitem > li:last-child > a {
    padding-bottom: 80px;
  }

}

@media only screen and (max-width: 991px) {
  .wsmenu .wsmenu-list > li > .wsshoptabing > .wsshopwp > .wstabitem > li > .wsmenu-click02 i::before {
    border-width: 1px 1px 0 0;
    border-style: solid;
    border-color: #555555;

    }

    .wsmenu > .wsmenu-list > li#primoItemMenu:hover > a {
      background-color: #fff;
      color: #fff;
    }

  }
/* Fine MENU TENDINA OTTIMIZZAZIONE ESTREMA */

@media only screen and (max-width: 1238px) and (min-width:992px) {
.wsmenu > .wsmenu-list > .wsshopmyaccount, .wscarticon > span.testicone {
  font-size: 10px;
}

.column.column2 {
  flex-grow: 2;
  padding-right: 20px;
}

}

div#headerBib.scrollo{
-webkit-box-shadow: 0 0 5px rgba(0,0,0,0.16);
-moz-box-shadow: 0 0 5px rgba(0,0,0,0.16);
box-shadow: 0 0 5px rgba(0,0,0,0.16)!important;
}

.primordine {
   position: relative;
    left: 25%;
    top: 0px;
    transform: translate(-50%, -50%);
    padding: 0px; 
    font-weight: 600;
}

#slider_textsli, #slider_textsli .slide_textsli {
  width: 690px;
}


#minicart-container > div.itemsContainer > div:nth-child(1) > div.colprezzo > div.product-prezzo-quant-box > div > input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
                -webkit-appearance: inner-spin-button;
                margin: 0;
                opacity: 1;
            }

  
#minicart-container > div.itemsContainer > div:nth-child(1) > div.colprezzo > div.product-prezzo-quant-box > div > input[type="number"] {
              -moz-appearance: auto;
            }



            @media only screen and (max-width: 320px) {
              #header23 .itemCartRight img {
              width: 35px;
              padding-top: 8px;
            }
            
            #header23  div.top-container, div.itemsContainer, div.bottom-container {
              padding: 0px 18px;
            }
            
            #header23 .product-removal {
            
              font-size: 20px;
            }
            
            #header23 .revenueitemCartRight.product-line-price2 {
            
              font-size: 12px;
              line-height: 33px;
              width: 110px;
            }
            
            #header23 .itemCartRight .product-quantity2 input.quanti {
              padding: 0 4px 0px 8px;
              font-size: 12px;
            }
            
            #header23 .itemCartRight .product-quantity2 {
              margin-left: 2px;
            }
            
            #header23 .itemCartRight .product-quantity2 {
              margin-right: 1px;
            }
            
            #header23 .minicart-container .itemsContainer {
              max-height: 60vh;
              overflow-y: scroll;
              overflow-x: hidden;
              padding-bottom: 100px;
            }
            
            #header23 .minicart-container .bottom-container {
              padding: 0px 20px 20px 20px;
            
            }
            
            #header23 .minicart-container .bottom-container .btn-header.checkout {
              padding: 10px 10px;
            }
            
            #header23 .btn-header.carrello {
              margin-top: 10px;
            }
            
            #header23 .minicart-container .bottom-container .btn-header.carrello {
              font-size: 14px;
              border-radius: 4px;
              padding: 6px 10px;
            }
            
            #header23 .minicart-container .bottom-container .btn-header.carrello:hover {
            
              font-size: 14px;
              padding: 6px 10px;
              text-transform: uppercase;
            }
            
            #header23 .minicart-container .bottom-container .btn-header.checkout:hover {
              font-size: 14px;
            }
            
            #header23 .btn-header.carrello {
              margin-top: 10px;
            }
            
            #header23 .minicart-container .bottom-container .btn-header.checkout {
              padding: 10px 10px;
              font-size: 14px;
            }
            
            #header23 .cart-title {
              margin-bottom: 10px;
            }
            
            #header23 .minicart-container .close-cart iconify-icon {
              font-size: 30px;
            }
            
            #header23.minicart-container .close-cart {
            
              width: 34px;
              top: 10px;
            }
            
            #header23 .minicart-container.show-cart {
              padding: 15px 0px;
            }
            
            }


            /* skin 6 */


#header23 .skin-6 .num-in {
  background-color: #fff;
	float: left;
	width: 94px;
	height: 28px;
	padding: 0px 0;
	border-radius: 4px;
  border: 1px solid #D9D9D9;
}

#header23 .skin-6 .num-in span {
	display: block;
	float: left;
	width: 24px;
  line-height: 26px;
  height: 26px;
  border: 0px;
  color: #555;
  font-size: 16px;
  font-weight: 600;
	text-align: center;
	cursor: pointer;
	-webkit-transition: all 0.3;
	-o-transition: all 0.3;
	transition: all 0.3;
}

#header23 .skin-6 .num-in span:hover {
	background-color: #D9D9D9 !important;
}

#header23 .skin-6 .num-in input {
	float: left;
	height: 26px;
	width: 44px;
  appearance: textfield;
  color: #555;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #cdcaca;
  border-top-width: 0px;
  border-bottom-width: 0px;
  font-family: "Inter", sans-serif!important;
}

#header23 .colprezzo{
  text-align: center;
  font-family: inter, Sans serif !important;
  padding-left: 0px;
  margin: 0 auto;
}
#header23 .quantityLabelColPrezzo{
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 18px;
  text-align: center;
  color: #777;
  font-family: "Inter", sans-serif!important;
}

#header23 .revenueitemCartRight.product-line-price2 {
  line-height: 13px;
  width: 68px;
}

#header23 .revenueitemCartRight.product-line-price2 {
  line-height: 13px;
  height: 34px;
}

#header23 .product-removal {
  text-align: right;
  width: 60px;
  margin-bottom: 5px;
  padding: 0px;
  line-height: 20px;
  font-size: 20px;
  color: #777;
}

#header23 .revenueitemCartRight {
padding-top: 10px; 
}

#header23 span.svuotaCarrelloRightCart {

  color: #555;
}



@media screen and (max-width: 425px) and (min-width: 320px) {
  #header23 .colprezzo {
    width: 86px;
    padding-left: 10px;
  } 
  #header23 .skin-6 .num-in {
    width: 82px;
    padding: 0px;
    line-height: 24px;
  }

  #header23 .skin-6 .num-in input {
    width: 38px;
    height: 26px;
  }

  #header23 .skin-6 .num-in span {
    width: 21px;
    line-height: 26px;
    height: 26px;
  }

}

@media only screen and (max-width: 320px){
#header23 div.top-container, #header23 div.itemsContainer, #header23 div.bottom-container {
  padding: 0px 15px;
  padding-bottom:0px;
}
#header23 .dataitemCartRight {
  width: 190px;
 
}

}

#header23 div.top-container, #header23 div.itemsContainer, #header23 div.bottom-container {
  padding: 0px 20px;

}
/* / skin 6 */


